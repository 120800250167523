.icon-menu-icon {
  height: 24px;
  width: 24px;
}

.icon-menu-icon .icon {
  height: 24px;
  position: relative;
}

.icon-menu-icon .subtract {
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}

.icon-menu-icon.support {
  background-size: 100% 100%;
}

.icon-menu-icon.wallet {
  background-size: 100% 100%;
}

.icon-menu-icon.calendar {
  background-size: 100% 100%;
}

.icon-menu-icon.stroke {
  background-size: 100% 100%;
}

.icon-menu-icon.address {
  background-size: 100% 100%;
}

.icon-menu-icon.refer-a-friend {
  background-size: 100% 100%;
}

.icon-menu-icon.notification {
  background-size: 100% 100%;
}

.icon-menu-icon.notification.theme {
  background-image: url(../../../static/img/icon-8.svg);
}

.icon-menu-icon.black.calendar {
  background-image: url(../../../static/img/calendar-4.svg);
}

.icon-menu-icon.soft-gray.calendar {
  background-image: url(../../../static/img/calendar-1.svg);
}

.icon-menu-icon.support.grey {
  background-image: url(../../../static/img/icon-21.svg);
}

.icon-menu-icon.grey.calendar {
  background-image: url(../../../static/img/icon-type-light-icon-name-calendar-size-24px.svg);
}

.icon-menu-icon.support.soft-gray {
  background-image: url(../../../static/img/icon-20.svg);
}

.icon-menu-icon.wallet.black {
  background-image: url(../../../static/img/icon-24px-fill-2.svg);
}

.icon-menu-icon.refer-a-friend.soft-gray {
  background-image: url(../../../static/img/icon-15.svg);
}

.icon-menu-icon.theme.address {
  background-image: url(../../../static/img/icon-3.svg);
}

.icon-menu-icon.address.soft-gray {
  background-image: url(../../../static/img/icon-6.svg);
}

.icon-menu-icon.support.black {
  background-image: url(../../../static/img/icon-19.svg);
}

.icon-menu-icon.white.wallet {
  background-image: url(../../../static/img/icon-24px-fill-1.svg);
}

.icon-menu-icon.offers.grey {
  background-image: url(../../../static/img/icon-14.svg);
}

.icon-menu-icon.white.address {
  background-image: url(../../../static/img/icon-4.svg);
}

.icon-menu-icon.notification.soft-gray {
  background-image: url(../../../static/img/icon-11.svg);
}

.icon-menu-icon.wallet.grey {
  background-image: url(../../../static/img/icon-type-light-icon-name-wallet-size-24px.svg);
}

.icon-menu-icon.theme.refer-a-friend {
  background-image: url(../../../static/img/icon.svg);
}

.icon-menu-icon.grey.address {
  background-image: url(../../../static/img/icon-7.svg);
}

.icon-menu-icon.theme.wallet {
  background-image: url(../../../static/img/icon-24px-fill.svg);
}

.icon-menu-icon.notification.black {
  background-image: url(../../../static/img/icon-10.svg);
}

.icon-menu-icon.theme.calendar {
  background-image: url(../../../static/img/calendar-2.svg);
}

.icon-menu-icon.white.support {
  background-image: url(../../../static/img/icon-18.svg);
}

.icon-menu-icon.white.refer-a-friend {
  background-image: url(../../../static/img/icon-1.svg);
}

.icon-menu-icon.grey.refer-a-friend {
  background-image: url(../../../static/img/icon-16.svg);
}

.icon-menu-icon.wallet.soft-gray {
  background-image: url(../../../static/img/icon-24px-stroke-1.svg);
}

.icon-menu-icon.white.calendar {
  background-image: url(../../../static/img/calendar-3.svg);
}

.icon-menu-icon.support.theme {
  background-image: url(../../../static/img/icon-17.svg);
}

.icon-menu-icon.offers.soft-gray {
  background-image: url(../../../static/img/icon-13.svg);
}

.icon-menu-icon.white.notification {
  background-image: url(../../../static/img/icon-9.svg);
}

.icon-menu-icon.notification.grey {
  background-image: url(../../../static/img/icon-type-light-icon-name-notification-size-24px.svg);
}

.icon-menu-icon.refer-a-friend.black {
  background-image: url(../../../static/img/icon-2.svg);
}

.icon-menu-icon.address.black {
  background-image: url(../../../static/img/icon-5.svg);
}
