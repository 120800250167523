:root {
  --accessibilityblack: rgba(0, 0, 0, 1);
  --accessibilitywhite: rgba(255, 255, 255, 1);
  --backgroundbackground: var(--theme-background-background);
  --backgroundbackground-active: var(--theme-background-background-active);
  --backgroundbackground-brand: var(--theme-background-background-brand);
  --backgroundbackground-hover: var(--theme-background-background-hover);
  --backgroundbackground-inverse: var(--theme-background-background-inverse);
  --backgroundbackground-inverse-hover: var(--theme-background-background-inverse-hover);
  --backgroundbackground-selected: var(--theme-background-background-selected);
  --backgroundbackground-selected-hover: var(--theme-background-background-selected-hover);
  --basebg: rgba(202, 191, 255, 1);
  --baseicon: rgba(21, 0, 128, 1);
  --basetext: rgba(21, 0, 128, 1);
  --bg: rgba(245, 247, 252, 1);
  --black: rgba(35, 39, 45, 1);
  --blink-black: rgba(66, 66, 66, 1);
  --blink-body-2-font-family: "DMSans-SemiBold", Helvetica;
  --blink-body-2-font-size: 14px;
  --blink-body-2-font-style: normal;
  --blink-body-2-font-weight: 600;
  --blink-body-2-letter-spacing: 0px;
  --blink-body-2-line-height: 110.00000238418579%;
  --blink-body-font-family: "DMSans-SemiBold", Helvetica;
  --blink-body-font-size: 16px;
  --blink-body-font-style: normal;
  --blink-body-font-weight: 600;
  --blink-body-letter-spacing: 0px;
  --blink-body-line-height: 110.00000238418579%;
  --blink-green: rgba(158, 236, 143, 1);
  --blink-h1-font-family: "WorkSans-Medium", Helvetica;
  --blink-h1-font-size: 96px;
  --blink-h1-font-style: normal;
  --blink-h1-font-weight: 500;
  --blink-h1-letter-spacing: 0px;
  --blink-h1-line-height: 110.00000238418579%;
  --blink-h2-font-family: "WorkSans-Medium", Helvetica;
  --blink-h2-font-size: 60px;
  --blink-h2-font-style: normal;
  --blink-h2-font-weight: 500;
  --blink-h2-letter-spacing: 0px;
  --blink-h2-line-height: 110.00000238418579%;
  --blink-h3-font-family: "WorkSans-SemiBold", Helvetica;
  --blink-h3-font-size: 48px;
  --blink-h3-font-style: normal;
  --blink-h3-font-weight: 600;
  --blink-h3-letter-spacing: 0px;
  --blink-h3-line-height: 110.00000238418579%;
  --blink-h4-font-family: "WorkSans-SemiBold", Helvetica;
  --blink-h4-font-size: 32px;
  --blink-h4-font-style: normal;
  --blink-h4-font-weight: 600;
  --blink-h4-letter-spacing: 0px;
  --blink-h4-line-height: 110.00000238418579%;
  --blink-h5-font-family: "WorkSans-SemiBold", Helvetica;
  --blink-h5-font-size: 20px;
  --blink-h5-font-style: normal;
  --blink-h5-font-weight: 600;
  --blink-h5-letter-spacing: 0px;
  --blink-h5-line-height: 110.00000238418579%;
  --blink-label-font-family: "WorkSans-Bold", Helvetica;
  --blink-label-font-size: 12px;
  --blink-label-font-style: normal;
  --blink-label-font-weight: 700;
  --blink-label-letter-spacing: 0.75px;
  --blink-label-line-height: 110.00000238418579%;
  --blinkblack: rgba(66, 66, 66, 1);
  --blinkblue: rgba(93, 119, 252, 0.93);
  --blinkgreen: rgba(158, 236, 143, 0.93);
  --blinkgrey-00: rgba(224, 224, 224, 1);
  --blinkgrey-000: rgba(250, 250, 250, 0.93);
  --blinkgrey-20: rgba(189, 189, 189, 1);
  --blinkgrey-40: rgba(158, 158, 158, 1);
  --blinkgrey-60: rgba(117, 117, 117, 1);
  --blinkgrey-80: rgba(97, 97, 97, 1);
  --blinkgrey-snow: rgba(245, 245, 245, 0.93);
  --blinkgrey-white: rgba(238, 238, 238, 0.93);
  --blinkoragne: rgba(255, 107, 58, 0.93);
  --blinkwhite: rgba(255, 255, 255, 0.93);
  --blinkyellow: rgba(252, 226, 32, 0.93);
  --body-1-font-family: "DMSans-SemiBold", Helvetica;
  --body-1-font-size: 16px;
  --body-1-font-style: normal;
  --body-1-font-weight: 600;
  --body-1-letter-spacing: 0px;
  --body-1-line-height: 139.9999976158142%;
  --body-2-bold-font-family: "DMSans-Bold", Helvetica;
  --body-2-bold-font-size: 14px;
  --body-2-bold-font-style: normal;
  --body-2-bold-font-weight: 700;
  --body-2-bold-letter-spacing: 0px;
  --body-2-bold-line-height: 150%;
  --body-2-font-family: "DMSans-Medium", Helvetica;
  --body-2-font-size: 14px;
  --body-2-font-style: normal;
  --body-2-font-weight: 500;
  --body-2-letter-spacing: 0px;
  --body-2-line-height: 150%;
  --border-disabled-inner-top-bottom: ;
  --border-disabled-inner-top-left-bottom: ;
  --border-disabled-inner-top-right-bottom: ;
  --border-interactive-inner-border-all: ;
  --border-interactive-inner-border-bottom: ;
  --border-interactive-inner-border-left: ;
  --border-interactive-inner-border-right: ;
  --border-interactive-inner-border-top: ;
  --border-interactive-outer-border-all: 0px 0px 0px 3px rgba(15, 98, 254, 1);
  --border-interactive-outer-border-bottom: 0px 3px 0px 0px rgba(15, 98, 254, 1);
  --border-interactive-outer-border-left: -3px 0px 0px 0px rgba(15, 98, 254, 1);
  --border-interactive-outer-border-right: 3px 0px 0px 0px rgba(15, 98, 254, 1);
  --border-interactive-outer-border-top: 0px -3px 0px 0px rgba(15, 98, 254, 1);
  --border-strong-01-inner-border-all: ;
  --border-strong-01-inner-border-bottom: ;
  --border-strong-01-inner-border-left: ;
  --border-strong-01-inner-border-right: ;
  --border-strong-01-inner-border-top: ;
  --border-strong-01-inner-bottom-left-right: ;
  --border-strong-01-inner-left-right: ;
  --border-strong-01-inner-top-bottom: ;
  --border-strong-01-inner-top-left-bottom: ;
  --border-strong-01-inner-top-left-right: ;
  --border-strong-01-inner-top-right-bottom: ;
  --border-strong-02-inner-border-all: ;
  --border-strong-02-inner-border-bottom: ;
  --border-strong-02-inner-border-left: ;
  --border-strong-02-inner-border-right: ;
  --border-strong-02-inner-border-top: ;
  --border-strong-02-inner-bottom-left-right: ;
  --border-strong-02-inner-left-right: ;
  --border-strong-02-inner-top-bottom: ;
  --border-strong-02-inner-top-left-bottom: ;
  --border-strong-02-inner-top-left-right: ;
  --border-strong-02-inner-top-right-bottom: ;
  --border-strong-03-inner-border-all: ;
  --border-strong-03-inner-border-bottom: ;
  --border-strong-03-inner-border-left: ;
  --border-strong-03-inner-border-right: ;
  --border-strong-03-inner-border-top: ;
  --border-strong-03-inner-bottom-left-right: ;
  --border-strong-03-inner-left-right: ;
  --border-strong-03-inner-top-bottom: ;
  --border-strong-03-inner-top-left-bottom: ;
  --border-strong-03-inner-top-left-right: ;
  --border-strong-03-inner-top-right-bottom: ;
  --border-subtle-01-inner-border-all: ;
  --border-subtle-01-inner-border-bottom: ;
  --border-subtle-01-inner-border-left: ;
  --border-subtle-01-inner-border-right: ;
  --border-subtle-01-inner-border-top: ;
  --border-subtle-01-inner-bottom-left-right: ;
  --border-subtle-01-inner-left-right: ;
  --border-subtle-01-inner-top-bottom: ;
  --border-subtle-01-inner-top-left-bottom: ;
  --border-subtle-01-inner-top-left-right: ;
  --border-subtle-01-inner-top-right-bottom: ;
  --border-subtle-02-inner-border-all: ;
  --border-subtle-02-inner-border-bottom: ;
  --border-subtle-02-inner-border-left: ;
  --border-subtle-02-inner-border-right: ;
  --border-subtle-02-inner-border-top: ;
  --border-subtle-02-inner-bottom-left-right: ;
  --border-subtle-02-inner-left-right: ;
  --border-subtle-02-inner-top-bottom: ;
  --border-subtle-02-inner-top-left-bottom: ;
  --border-subtle-02-inner-top-left-right: ;
  --border-subtle-02-inner-top-right-bottom: ;
  --border-subtle-03-inner-border-all: ;
  --border-subtle-03-inner-border-bottom: ;
  --border-subtle-03-inner-border-left: ;
  --border-subtle-03-inner-border-right: ;
  --border-subtle-03-inner-border-top: ;
  --border-subtle-03-inner-bottom-left-right: ;
  --border-subtle-03-inner-left-right: ;
  --border-subtle-03-inner-top-bottom: ;
  --border-subtle-03-inner-top-left-bottom: ;
  --border-subtle-03-inner-top-left-right: ;
  --border-subtle-03-inner-top-right-bottom: ;
  --borderborder-disabled: var(--theme-border-border-disabled);
  --borderborder-interactive: var(--theme-border-border-interactive);
  --borderborder-inverse: var(--theme-border-border-inverse);
  --borderborder-strong-01: var(--theme-border-border-strong-01);
  --borderborder-strong-02: var(--theme-border-border-strong-02);
  --borderborder-strong-03: var(--theme-border-border-strong-03);
  --borderborder-subtle-00: var(--theme-border-border-subtle-00);
  --borderborder-subtle-01: var(--theme-border-border-subtle-01);
  --borderborder-subtle-02: var(--theme-border-border-subtle-02);
  --borderborder-subtle-03: var(--theme-border-border-subtle-03);
  --borderborder-subtle-selected-01: var(--theme-border-border-subtle-selected-01);
  --borderborder-subtle-selected-02: var(--theme-border-border-subtle-selected-02);
  --borderborder-subtle-selected-03: var(--theme-border-border-subtle-selected-03);
  --borderborder-tile-01: var(--theme-border-border-tile-01);
  --borderborder-tile-02: var(--theme-border-border-tile-02);
  --borderborder-tile-03: var(--theme-border-border-tile-03);
  --button-font-family: "ProximaNova-Bold", Helvetica;
  --button-font-size: 16px;
  --button-font-style: normal;
  --button-font-weight: 700;
  --button-letter-spacing: 0px;
  --button-line-height: 110.00000238418579%;
  --buttonbutton-danger-active: var(--theme-button-button-danger-active);
  --buttonbutton-danger-hover: var(--theme-button-button-danger-hover);
  --buttonbutton-danger-primary: var(--theme-button-button-danger-primary);
  --buttonbutton-danger-secondary: var(--theme-button-button-danger-secondary);
  --buttonbutton-disabled: var(--theme-button-button-disabled);
  --buttonbutton-primary: var(--theme-button-button-primary);
  --buttonbutton-primary-active: var(--theme-button-button-primary-active);
  --buttonbutton-primary-hover: var(--theme-button-button-primary-hover);
  --buttonbutton-secondary: var(--theme-button-button-secondary);
  --buttonbutton-secondary-active: var(--theme-button-button-secondary-active);
  --buttonbutton-secondary-hover: var(--theme-button-button-secondary-hover);
  --buttonbutton-separator: var(--theme-button-button-separator);
  --buttonbutton-tertiary: var(--theme-button-button-tertiary);
  --buttonbutton-tertiary-active: var(--theme-button-button-tertiary-active);
  --buttonbutton-tertiary-hover: var(--theme-button-button-tertiary-hover);
  --coloraccent-05: rgba(255, 216, 141, 1);
  --colors-default-neutral-black: rgba(0, 0, 0, 1);
  --colors-default-neutral-white: rgba(255, 255, 255, 1);
  --fieldfield-01: var(--theme-field-field-01);
  --fieldfield-02: var(--theme-field-field-02);
  --fieldfield-03: var(--theme-field-field-03);
  --fieldfield-hover-01: var(--theme-field-field-hover-01);
  --fieldfield-hover-02: var(--theme-field-field-hover-02);
  --fieldfield-hover-03: var(--theme-field-field-hover-03);
  --figma-variantborder: rgba(151, 71, 255, 1);
  --focus-inner-border-all: ;
  --focus-inverse-inner-border-all: ;
  --focus-inverse-outer-border-all: 0px 0px 0px 2px rgba(255, 255, 255, 1);
  --focusfocus: var(--theme-focus-focus);
  --focusfocus-inset: var(--theme-focus-focus-inset);
  --focusfocus-inverse: var(--theme-focus-focus-inverse);
  --gradientgradient: rgba(244, 244, 244, 1);
  --heading-3-font-family: "WorkSans-SemiBold", Helvetica;
  --heading-3-font-size: 48px;
  --heading-3-font-style: normal;
  --heading-3-font-weight: 600;
  --heading-3-letter-spacing: 0px;
  --heading-3-line-height: normal;
  --heading-4-font-family: "WorkSans-SemiBold", Helvetica;
  --heading-4-font-size: 32px;
  --heading-4-font-style: normal;
  --heading-4-font-weight: 600;
  --heading-4-letter-spacing: 0px;
  --heading-4-line-height: normal;
  --heading-5-font-family: "WorkSans-SemiBold", Helvetica;
  --heading-5-font-size: 20px;
  --heading-5-font-style: normal;
  --heading-5-font-weight: 600;
  --heading-5-letter-spacing: 0px;
  --heading-5-line-height: 129.99999523162842%;
  --iconicon-disabled: var(--theme-icon-icon-disabled);
  --iconicon-interactive: var(--theme-icon-icon-interactive);
  --iconicon-inverse: var(--theme-icon-icon-inverse);
  --iconicon-on-color: var(--theme-icon-icon-on-color);
  --iconicon-on-color-disabled: var(--theme-icon-icon-on-color-disabled);
  --iconicon-primary: var(--theme-icon-icon-primary);
  --iconicon-secondary: var(--theme-icon-icon-secondary);
  --layer-accentlayer-accent-01: var(--theme-layer-accent-layer-accent-01);
  --layer-accentlayer-accent-02: var(--theme-layer-accent-layer-accent-02);
  --layer-accentlayer-accent-03: var(--theme-layer-accent-layer-accent-03);
  --layer-accentlayer-accent-active-01: var(--theme-layer-accent-layer-accent-active-01);
  --layer-accentlayer-accent-active-02: var(--theme-layer-accent-layer-accent-active-02);
  --layer-accentlayer-accent-active-03: var(--theme-layer-accent-layer-accent-active-03);
  --layer-accentlayer-accent-hover-01: var(--theme-layer-accent-layer-accent-hover-01);
  --layer-accentlayer-accent-hover-02: var(--theme-layer-accent-layer-accent-hover-02);
  --layer-accentlayer-accent-hover-03: var(--theme-layer-accent-layer-accent-hover-03);
  --layerlayer-01: var(--theme-layer-layer-01);
  --layerlayer-02: var(--theme-layer-layer-02);
  --layerlayer-03: var(--theme-layer-layer-03);
  --layerlayer-active-01: var(--theme-layer-layer-active-01);
  --layerlayer-active-02: var(--theme-layer-layer-active-02);
  --layerlayer-active-03: var(--theme-layer-layer-active-03);
  --layerlayer-hover-01: var(--theme-layer-layer-hover-01);
  --layerlayer-hover-02: var(--theme-layer-layer-hover-02);
  --layerlayer-hover-03: var(--theme-layer-layer-hover-03);
  --layerlayer-selected-01: var(--theme-layer-layer-selected-01);
  --layerlayer-selected-02: var(--theme-layer-layer-selected-02);
  --layerlayer-selected-03: var(--theme-layer-layer-selected-03);
  --layerlayer-selected-disabled: var(--theme-layer-layer-selected-disabled);
  --layerlayer-selected-hover-01: var(--theme-layer-layer-selected-hover-01);
  --layerlayer-selected-hover-02: var(--theme-layer-layer-selected-hover-02);
  --layerlayer-selected-hover-03: var(--theme-layer-layer-selected-hover-03);
  --layerlayer-selected-inverse: var(--theme-layer-layer-selected-inverse);
  --linklink-inverse: var(--theme-link-link-inverse);
  --linklink-primary: var(--theme-link-link-primary);
  --linklink-primary-hover: var(--theme-link-link-primary-hover);
  --linklink-secondary: var(--theme-link-link-secondary);
  --linklink-visted: var(--theme-link-link-visited);
  --menu-transparent: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  --miscellaneoushighlight: var(--theme-miscellaneous-highlight);
  --miscellaneousinteractive: var(--theme-miscellaneous-interactive);
  --miscellaneousoverlay: var(--theme-miscellaneous-overlay);
  --miscellaneoustoggle-off: rgba(238, 238, 238, 1);
  --notificationsnotification-action-hover: rgba(255, 255, 255, 1);
  --notificationsnotification-action-tertiary-inverse: rgba(255, 255, 255, 1);
  --notificationsnotification-action-tertiary-inverse-hover: rgba(244, 244, 244, 1);
  --notificationsnotification-action-tertiary-inverse-text: rgba(22, 22, 22, 1);
  --notificationsnotification-error-background: rgba(255, 241, 241, 1);
  --notificationsnotification-info-background: rgba(237, 245, 255, 1);
  --notificationsnotification-success-background: rgba(222, 251, 230, 1);
  --notificationsnotification-warning-background: rgba(252, 244, 214, 1);
  --notificationssupport-error-border: rgba(218, 30, 40, 0.3);
  --notificationssupport-info-border: rgba(0, 67, 206, 0.3);
  --notificationssupport-success-border: rgba(36, 161, 72, 0.3);
  --notificationssupport-warning-border: rgba(241, 194, 27, 0.3);
  --selected-inverse-inner-border-all: ;
  --selected-inverse-inner-border-bottom: ;
  --selected-inverse-inner-border-left: ;
  --selected-inverse-inner-border-right: ;
  --selected-inverse-inner-border-top: ;
  --selected-inverse-outer-border-all: 0px 0px 0px 3px rgba(15, 98, 254, 1);
  --selected-inverse-outer-border-bottom: 0px 3px 0px 0px rgba(15, 98, 254, 1);
  --selected-inverse-outer-border-left: -3px 0px 0px 0px rgba(15, 98, 254, 1);
  --selected-inverse-outer-border-right: 3px 0px 0px 0px rgba(15, 98, 254, 1);
  --selected-inverse-outer-border-top: 0px -3px 0px 0px rgba(15, 98, 254, 1);
  --shadows-menu: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  --skeletonskeleton-background: var(--theme-miscellaneous-skeleton-background);
  --skeletonskeleton-element: var(--theme-miscellaneous-skeleton-element);
  --spacing-64px-4rem-spacing-10: 64px;
  --spacing-96px-6rem-spacing-12: 96px;
  --support-error-inner-border-all: ;
  --support-error-outer-border-all: 0px 0px 0px 2px rgba(218, 30, 40, 1);
  --support-inversesupport-error-inverse: var(--theme-support-support-error-inverse);
  --support-inversesupport-info-inverse: var(--theme-support-support-info-inverse);
  --support-inversesupport-success-inverse: var(--theme-support-support-success-inverse);
  --support-inversesupport-warning-inverse: var(--theme-support-support-warning-inverse);
  --supportsupport-caution-major: rgba(255, 131, 43, 1);
  --supportsupport-caution-minor: rgba(241, 194, 27, 1);
  --supportsupport-error: var(--theme-support-support-error);
  --supportsupport-info: var(--theme-support-support-info);
  --supportsupport-success: var(--theme-support-support-success);
  --supportsupport-undefined: rgba(138, 63, 252, 1);
  --supportsupport-warning: var(--theme-support-support-warning);
  --tagblue-background: var(--theme-tag-blue-tag-background-blue);
  --tagblue-hover: var(--theme-tag-blue-tag-hover-blue);
  --tagblue-text: var(--theme-tag-blue-tag-color-blue);
  --tagcool-gray-background: var(--theme-tag-cool-gray-tag-background-cool-gray);
  --tagcool-gray-hover: var(--theme-tag-cool-gray-tag-hover-gray);
  --tagcool-gray-text: var(--theme-tag-cool-gray-tag-color-cool-gray);
  --tagcyan-background: var(--theme-tag-cyan-tag-background-cyan);
  --tagcyan-hover: var(--theme-tag-cyan-tag-hover-cyan);
  --tagcyan-text: var(--theme-tag-cyan-tag-color-cyan);
  --taggray-background: var(--theme-tag-gray-tag-background-gray);
  --taggray-hover: var(--theme-tag-gray-tag-hover-gray);
  --taggray-text: var(--theme-tag-gray-tag-color-gray);
  --taggreen-background: var(--theme-tag-green-tag-background-green);
  --taggreen-hover: var(--theme-tag-green-tag-hover-green);
  --taggreen-text: var(--theme-tag-green-tag-color-green);
  --tagmagenta-background: var(--theme-tag-magenta-tag-background-magenta);
  --tagmagenta-hover: var(--theme-tag-magenta-tag-hover-magenta);
  --tagmagenta-text: var(--theme-tag-magenta-tag-color-magenta);
  --tagpurple-background: var(--theme-tag-purple-tag-background-purple);
  --tagpurple-hover: var(--theme-tag-purple-tag-hover-purple);
  --tagpurple-text: var(--theme-tag-purple-tag-color-purple);
  --tagred-background: var(--theme-tag-red-tag-background-red);
  --tagred-hover: var(--theme-tag-red-tag-hover-red);
  --tagred-text: var(--theme-tag-red-tag-color-red);
  --tagteal-background: var(--theme-tag-teal-tag-background-teal);
  --tagteal-hover: var(--theme-tag-teal-tag-hover-teal);
  --tagteal-text: var(--theme-tag-teal-tag-color-teal);
  --tagwarm-gray-background: var(--theme-tag-warm-gray-tag-background-warm-gray);
  --tagwarm-gray-hover: var(--theme-tag-warm-gray-tag-hover-gray);
  --tagwarm-gray-text: var(--theme-tag-warm-gray-tag-color-warm-gray);
  --texttext-disabled: var(--theme-text-text-disabled);
  --texttext-error: var(--theme-text-text-error);
  --texttext-helper: var(--theme-text-text-helper);
  --texttext-inverse: var(--theme-text-text-inverse);
  --texttext-on-color: var(--theme-text-text-on-color);
  --texttext-on-color-disabled: var(--theme-text-text-on-color-disabled);
  --texttext-placeholder: var(--theme-text-text-placeholder);
  --texttext-primary: var(--theme-text-text-primary);
  --texttext-secondary: var(--theme-text-text-secondary);
  --theme-background-background: var(--colors-default-neutral-white);
  --theme-background-background-active: rgba(141, 141, 141, 0.5);
  --theme-background-background-hover: rgba(141, 141, 141, 0.12);
  --theme-background-background-selected: rgba(141, 141, 141, 0.2);
  --theme-background-background-selected-hover: rgba(141, 141, 141, 0.32);
  --theme-field-field-02: var(--colors-default-neutral-white);
  --theme-focus-focus-inset: var(--colors-default-neutral-white);
  --theme-focus-focus-inverse: var(--colors-default-neutral-white);
  --theme-icon-icon-disabled: rgba(22, 22, 22, 0.25);
  --theme-icon-icon-inverse: var(--colors-default-neutral-white);
  --theme-icon-icon-on-color: var(--colors-default-neutral-white);
  --theme-inverse-black-white-black-in-light-themes: var(--colors-default-neutral-black);
  --theme-layer-layer-02: var(--colors-default-neutral-white);
  --theme-miscellaneous-overlay: rgba(22, 22, 22, 0.5);
  --theme-text-text-disabled: rgba(22, 22, 22, 0.25);
  --theme-text-text-inverse: var(--colors-default-neutral-white);
  --theme-text-text-on-color: var(--colors-default-neutral-white);
  --transparenttransparent: rgba(255, 255, 255, 1);
  --variable-collection-blink-green: rgba(158, 236, 143, 1);
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-theme-mode="white-theme">
    <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-theme-mode="white-theme"] {
  --theme-background-background-active: rgba(141, 141, 141, 0.5);
  --theme-background-background-hover: rgba(141, 141, 141, 0.12);
  --theme-background-background-selected: rgba(141, 141, 141, 0.2);
  --theme-icon-icon-disabled: rgba(22, 22, 22, 0.25);
  --theme-inverse-black-white-black-in-light-themes: var(--colors-default-neutral-black);
  --theme-miscellaneous-overlay: rgba(22, 22, 22, 0.5);
  --theme-text-text-disabled: rgba(22, 22, 22, 0.25);
}

[data-theme-mode="gray-10-theme"] {
  --theme-background-background-active: rgba(141, 141, 141, 0.5);
  --theme-background-background-hover: rgba(141, 141, 141, 0.12);
  --theme-background-background-selected: rgba(141, 141, 141, 0.2);
  --theme-icon-icon-disabled: rgba(22, 22, 22, 0.25);
  --theme-inverse-black-white-black-in-light-themes: var(--colors-default-neutral-black);
  --theme-miscellaneous-overlay: rgba(22, 22, 22, 0.5);
  --theme-text-text-disabled: rgba(22, 22, 22, 0.25);
}

[data-theme-mode="gray-90-theme"] {
  --theme-background-background-active: rgba(141, 141, 141, 0.4);
  --theme-background-background-hover: rgba(141, 141, 141, 0.16);
  --theme-background-background-selected: rgba(141, 141, 141, 0.24);
  --theme-icon-icon-disabled: rgba(244, 244, 244, 0.25);
  --theme-inverse-black-white-black-in-light-themes: var(--colors-default-neutral-white);
  --theme-miscellaneous-overlay: rgba(22, 22, 22, 0.7);
  --theme-text-text-disabled: rgba(244, 244, 244, 0.25);
}

[data-theme-mode="gray-100-theme"] {
  --theme-background-background-active: rgba(141, 141, 141, 0.4);
  --theme-background-background-hover: rgba(141, 141, 141, 0.16);
  --theme-background-background-selected: rgba(141, 141, 141, 0.24);
  --theme-icon-icon-disabled: rgba(244, 244, 244, 0.25);
  --theme-inverse-black-white-black-in-light-themes: var(--colors-default-neutral-white);
  --theme-miscellaneous-overlay: rgba(22, 22, 22, 0.7);
  --theme-text-text-disabled: rgba(244, 244, 244, 0.25);
}
