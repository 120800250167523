:root {
  --accessibilityblack: #000;
  --accessibilitywhite: #fff;
  --backgroundbackground: var(--theme-background-background);
  --backgroundbackground-active: var(--theme-background-background-active);
  --backgroundbackground-brand: var(--theme-background-background-brand);
  --backgroundbackground-hover: var(--theme-background-background-hover);
  --backgroundbackground-inverse: var(--theme-background-background-inverse);
  --backgroundbackground-inverse-hover: var(--theme-background-background-inverse-hover);
  --backgroundbackground-selected: var(--theme-background-background-selected);
  --backgroundbackground-selected-hover: var(--theme-background-background-selected-hover);
  --basebg: #cabfff;
  --baseicon: #150080;
  --basetext: #150080;
  --bg: #f5f7fc;
  --black: #23272d;
  --blink-black: #424242;
  --blink-body-2-font-family: "DMSans-SemiBold", Helvetica;
  --blink-body-2-font-size: 14px;
  --blink-body-2-font-style: normal;
  --blink-body-2-font-weight: 600;
  --blink-body-2-letter-spacing: 0px;
  --blink-body-2-line-height: 110.0%;
  --blink-body-font-family: "DMSans-SemiBold", Helvetica;
  --blink-body-font-size: 16px;
  --blink-body-font-style: normal;
  --blink-body-font-weight: 600;
  --blink-body-letter-spacing: 0px;
  --blink-body-line-height: 110.0%;
  --blink-green: #9eec8f;
  --blink-h1-font-family: "WorkSans-Medium", Helvetica;
  --blink-h1-font-size: 96px;
  --blink-h1-font-style: normal;
  --blink-h1-font-weight: 500;
  --blink-h1-letter-spacing: 0px;
  --blink-h1-line-height: 110.0%;
  --blink-h2-font-family: "WorkSans-Medium", Helvetica;
  --blink-h2-font-size: 60px;
  --blink-h2-font-style: normal;
  --blink-h2-font-weight: 500;
  --blink-h2-letter-spacing: 0px;
  --blink-h2-line-height: 110.0%;
  --blink-h3-font-family: "WorkSans-SemiBold", Helvetica;
  --blink-h3-font-size: 48px;
  --blink-h3-font-style: normal;
  --blink-h3-font-weight: 600;
  --blink-h3-letter-spacing: 0px;
  --blink-h3-line-height: 110.0%;
  --blink-h4-font-family: "WorkSans-SemiBold", Helvetica;
  --blink-h4-font-size: 32px;
  --blink-h4-font-style: normal;
  --blink-h4-font-weight: 600;
  --blink-h4-letter-spacing: 0px;
  --blink-h4-line-height: 110.0%;
  --blink-h5-font-family: "WorkSans-SemiBold", Helvetica;
  --blink-h5-font-size: 20px;
  --blink-h5-font-style: normal;
  --blink-h5-font-weight: 600;
  --blink-h5-letter-spacing: 0px;
  --blink-h5-line-height: 110.0%;
  --blink-label-font-family: "WorkSans-Bold", Helvetica;
  --blink-label-font-size: 12px;
  --blink-label-font-style: normal;
  --blink-label-font-weight: 700;
  --blink-label-letter-spacing: .75px;
  --blink-label-line-height: 110.0%;
  --blinkblack: #424242;
  --blinkblue: #5d77fced;
  --blinkgreen: #9eec8fed;
  --blinkgrey-00: #e0e0e0;
  --blinkgrey-000: #fafafaed;
  --blinkgrey-20: #bdbdbd;
  --blinkgrey-40: #9e9e9e;
  --blinkgrey-60: #757575;
  --blinkgrey-80: #616161;
  --blinkgrey-snow: #f5f5f5ed;
  --blinkgrey-white: #eeeeeeed;
  --blinkoragne: #ff6b3aed;
  --blinkwhite: #ffffffed;
  --blinkyellow: #fce220ed;
  --body-1-font-family: "DMSans-SemiBold", Helvetica;
  --body-1-font-size: 16px;
  --body-1-font-style: normal;
  --body-1-font-weight: 600;
  --body-1-letter-spacing: 0px;
  --body-1-line-height: 140.0%;
  --body-2-bold-font-family: "DMSans-Bold", Helvetica;
  --body-2-bold-font-size: 14px;
  --body-2-bold-font-style: normal;
  --body-2-bold-font-weight: 700;
  --body-2-bold-letter-spacing: 0px;
  --body-2-bold-line-height: 150%;
  --body-2-font-family: "DMSans-Medium", Helvetica;
  --body-2-font-size: 14px;
  --body-2-font-style: normal;
  --body-2-font-weight: 500;
  --body-2-letter-spacing: 0px;
  --body-2-line-height: 150%;
  --border-disabled-inner-top-bottom: ;
  --border-disabled-inner-top-left-bottom: ;
  --border-disabled-inner-top-right-bottom: ;
  --border-interactive-inner-border-all: ;
  --border-interactive-inner-border-bottom: ;
  --border-interactive-inner-border-left: ;
  --border-interactive-inner-border-right: ;
  --border-interactive-inner-border-top: ;
  --border-interactive-outer-border-all: 0px 0px 0px 3px #0f62fe;
  --border-interactive-outer-border-bottom: 0px 3px 0px 0px #0f62fe;
  --border-interactive-outer-border-left: -3px 0px 0px 0px #0f62fe;
  --border-interactive-outer-border-right: 3px 0px 0px 0px #0f62fe;
  --border-interactive-outer-border-top: 0px -3px 0px 0px #0f62fe;
  --border-strong-01-inner-border-all: ;
  --border-strong-01-inner-border-bottom: ;
  --border-strong-01-inner-border-left: ;
  --border-strong-01-inner-border-right: ;
  --border-strong-01-inner-border-top: ;
  --border-strong-01-inner-bottom-left-right: ;
  --border-strong-01-inner-left-right: ;
  --border-strong-01-inner-top-bottom: ;
  --border-strong-01-inner-top-left-bottom: ;
  --border-strong-01-inner-top-left-right: ;
  --border-strong-01-inner-top-right-bottom: ;
  --border-strong-02-inner-border-all: ;
  --border-strong-02-inner-border-bottom: ;
  --border-strong-02-inner-border-left: ;
  --border-strong-02-inner-border-right: ;
  --border-strong-02-inner-border-top: ;
  --border-strong-02-inner-bottom-left-right: ;
  --border-strong-02-inner-left-right: ;
  --border-strong-02-inner-top-bottom: ;
  --border-strong-02-inner-top-left-bottom: ;
  --border-strong-02-inner-top-left-right: ;
  --border-strong-02-inner-top-right-bottom: ;
  --border-strong-03-inner-border-all: ;
  --border-strong-03-inner-border-bottom: ;
  --border-strong-03-inner-border-left: ;
  --border-strong-03-inner-border-right: ;
  --border-strong-03-inner-border-top: ;
  --border-strong-03-inner-bottom-left-right: ;
  --border-strong-03-inner-left-right: ;
  --border-strong-03-inner-top-bottom: ;
  --border-strong-03-inner-top-left-bottom: ;
  --border-strong-03-inner-top-left-right: ;
  --border-strong-03-inner-top-right-bottom: ;
  --border-subtle-01-inner-border-all: ;
  --border-subtle-01-inner-border-bottom: ;
  --border-subtle-01-inner-border-left: ;
  --border-subtle-01-inner-border-right: ;
  --border-subtle-01-inner-border-top: ;
  --border-subtle-01-inner-bottom-left-right: ;
  --border-subtle-01-inner-left-right: ;
  --border-subtle-01-inner-top-bottom: ;
  --border-subtle-01-inner-top-left-bottom: ;
  --border-subtle-01-inner-top-left-right: ;
  --border-subtle-01-inner-top-right-bottom: ;
  --border-subtle-02-inner-border-all: ;
  --border-subtle-02-inner-border-bottom: ;
  --border-subtle-02-inner-border-left: ;
  --border-subtle-02-inner-border-right: ;
  --border-subtle-02-inner-border-top: ;
  --border-subtle-02-inner-bottom-left-right: ;
  --border-subtle-02-inner-left-right: ;
  --border-subtle-02-inner-top-bottom: ;
  --border-subtle-02-inner-top-left-bottom: ;
  --border-subtle-02-inner-top-left-right: ;
  --border-subtle-02-inner-top-right-bottom: ;
  --border-subtle-03-inner-border-all: ;
  --border-subtle-03-inner-border-bottom: ;
  --border-subtle-03-inner-border-left: ;
  --border-subtle-03-inner-border-right: ;
  --border-subtle-03-inner-border-top: ;
  --border-subtle-03-inner-bottom-left-right: ;
  --border-subtle-03-inner-left-right: ;
  --border-subtle-03-inner-top-bottom: ;
  --border-subtle-03-inner-top-left-bottom: ;
  --border-subtle-03-inner-top-left-right: ;
  --border-subtle-03-inner-top-right-bottom: ;
  --borderborder-disabled: var(--theme-border-border-disabled);
  --borderborder-interactive: var(--theme-border-border-interactive);
  --borderborder-inverse: var(--theme-border-border-inverse);
  --borderborder-strong-01: var(--theme-border-border-strong-01);
  --borderborder-strong-02: var(--theme-border-border-strong-02);
  --borderborder-strong-03: var(--theme-border-border-strong-03);
  --borderborder-subtle-00: var(--theme-border-border-subtle-00);
  --borderborder-subtle-01: var(--theme-border-border-subtle-01);
  --borderborder-subtle-02: var(--theme-border-border-subtle-02);
  --borderborder-subtle-03: var(--theme-border-border-subtle-03);
  --borderborder-subtle-selected-01: var(--theme-border-border-subtle-selected-01);
  --borderborder-subtle-selected-02: var(--theme-border-border-subtle-selected-02);
  --borderborder-subtle-selected-03: var(--theme-border-border-subtle-selected-03);
  --borderborder-tile-01: var(--theme-border-border-tile-01);
  --borderborder-tile-02: var(--theme-border-border-tile-02);
  --borderborder-tile-03: var(--theme-border-border-tile-03);
  --button-font-family: "ProximaNova-Bold", Helvetica;
  --button-font-size: 16px;
  --button-font-style: normal;
  --button-font-weight: 700;
  --button-letter-spacing: 0px;
  --button-line-height: 110.0%;
  --buttonbutton-danger-active: var(--theme-button-button-danger-active);
  --buttonbutton-danger-hover: var(--theme-button-button-danger-hover);
  --buttonbutton-danger-primary: var(--theme-button-button-danger-primary);
  --buttonbutton-danger-secondary: var(--theme-button-button-danger-secondary);
  --buttonbutton-disabled: var(--theme-button-button-disabled);
  --buttonbutton-primary: var(--theme-button-button-primary);
  --buttonbutton-primary-active: var(--theme-button-button-primary-active);
  --buttonbutton-primary-hover: var(--theme-button-button-primary-hover);
  --buttonbutton-secondary: var(--theme-button-button-secondary);
  --buttonbutton-secondary-active: var(--theme-button-button-secondary-active);
  --buttonbutton-secondary-hover: var(--theme-button-button-secondary-hover);
  --buttonbutton-separator: var(--theme-button-button-separator);
  --buttonbutton-tertiary: var(--theme-button-button-tertiary);
  --buttonbutton-tertiary-active: var(--theme-button-button-tertiary-active);
  --buttonbutton-tertiary-hover: var(--theme-button-button-tertiary-hover);
  --coloraccent-05: #ffd88d;
  --colors-default-neutral-black: #000;
  --colors-default-neutral-white: #fff;
  --fieldfield-01: var(--theme-field-field-01);
  --fieldfield-02: var(--theme-field-field-02);
  --fieldfield-03: var(--theme-field-field-03);
  --fieldfield-hover-01: var(--theme-field-field-hover-01);
  --fieldfield-hover-02: var(--theme-field-field-hover-02);
  --fieldfield-hover-03: var(--theme-field-field-hover-03);
  --figma-variantborder: #9747ff;
  --focus-inner-border-all: ;
  --focus-inverse-inner-border-all: ;
  --focus-inverse-outer-border-all: 0px 0px 0px 2px #fff;
  --focusfocus: var(--theme-focus-focus);
  --focusfocus-inset: var(--theme-focus-focus-inset);
  --focusfocus-inverse: var(--theme-focus-focus-inverse);
  --gradientgradient: #f4f4f4;
  --heading-3-font-family: "WorkSans-SemiBold", Helvetica;
  --heading-3-font-size: 48px;
  --heading-3-font-style: normal;
  --heading-3-font-weight: 600;
  --heading-3-letter-spacing: 0px;
  --heading-3-line-height: normal;
  --heading-4-font-family: "WorkSans-SemiBold", Helvetica;
  --heading-4-font-size: 32px;
  --heading-4-font-style: normal;
  --heading-4-font-weight: 600;
  --heading-4-letter-spacing: 0px;
  --heading-4-line-height: normal;
  --heading-5-font-family: "WorkSans-SemiBold", Helvetica;
  --heading-5-font-size: 20px;
  --heading-5-font-style: normal;
  --heading-5-font-weight: 600;
  --heading-5-letter-spacing: 0px;
  --heading-5-line-height: 130.0%;
  --iconicon-disabled: var(--theme-icon-icon-disabled);
  --iconicon-interactive: var(--theme-icon-icon-interactive);
  --iconicon-inverse: var(--theme-icon-icon-inverse);
  --iconicon-on-color: var(--theme-icon-icon-on-color);
  --iconicon-on-color-disabled: var(--theme-icon-icon-on-color-disabled);
  --iconicon-primary: var(--theme-icon-icon-primary);
  --iconicon-secondary: var(--theme-icon-icon-secondary);
  --layer-accentlayer-accent-01: var(--theme-layer-accent-layer-accent-01);
  --layer-accentlayer-accent-02: var(--theme-layer-accent-layer-accent-02);
  --layer-accentlayer-accent-03: var(--theme-layer-accent-layer-accent-03);
  --layer-accentlayer-accent-active-01: var(--theme-layer-accent-layer-accent-active-01);
  --layer-accentlayer-accent-active-02: var(--theme-layer-accent-layer-accent-active-02);
  --layer-accentlayer-accent-active-03: var(--theme-layer-accent-layer-accent-active-03);
  --layer-accentlayer-accent-hover-01: var(--theme-layer-accent-layer-accent-hover-01);
  --layer-accentlayer-accent-hover-02: var(--theme-layer-accent-layer-accent-hover-02);
  --layer-accentlayer-accent-hover-03: var(--theme-layer-accent-layer-accent-hover-03);
  --layerlayer-01: var(--theme-layer-layer-01);
  --layerlayer-02: var(--theme-layer-layer-02);
  --layerlayer-03: var(--theme-layer-layer-03);
  --layerlayer-active-01: var(--theme-layer-layer-active-01);
  --layerlayer-active-02: var(--theme-layer-layer-active-02);
  --layerlayer-active-03: var(--theme-layer-layer-active-03);
  --layerlayer-hover-01: var(--theme-layer-layer-hover-01);
  --layerlayer-hover-02: var(--theme-layer-layer-hover-02);
  --layerlayer-hover-03: var(--theme-layer-layer-hover-03);
  --layerlayer-selected-01: var(--theme-layer-layer-selected-01);
  --layerlayer-selected-02: var(--theme-layer-layer-selected-02);
  --layerlayer-selected-03: var(--theme-layer-layer-selected-03);
  --layerlayer-selected-disabled: var(--theme-layer-layer-selected-disabled);
  --layerlayer-selected-hover-01: var(--theme-layer-layer-selected-hover-01);
  --layerlayer-selected-hover-02: var(--theme-layer-layer-selected-hover-02);
  --layerlayer-selected-hover-03: var(--theme-layer-layer-selected-hover-03);
  --layerlayer-selected-inverse: var(--theme-layer-layer-selected-inverse);
  --linklink-inverse: var(--theme-link-link-inverse);
  --linklink-primary: var(--theme-link-link-primary);
  --linklink-primary-hover: var(--theme-link-link-primary-hover);
  --linklink-secondary: var(--theme-link-link-secondary);
  --linklink-visted: var(--theme-link-link-visited);
  --menu-transparent: 0px 2px 6px 0px #0000004d;
  --miscellaneoushighlight: var(--theme-miscellaneous-highlight);
  --miscellaneousinteractive: var(--theme-miscellaneous-interactive);
  --miscellaneousoverlay: var(--theme-miscellaneous-overlay);
  --miscellaneoustoggle-off: #eee;
  --notificationsnotification-action-hover: #fff;
  --notificationsnotification-action-tertiary-inverse: #fff;
  --notificationsnotification-action-tertiary-inverse-hover: #f4f4f4;
  --notificationsnotification-action-tertiary-inverse-text: #161616;
  --notificationsnotification-error-background: #fff1f1;
  --notificationsnotification-info-background: #edf5ff;
  --notificationsnotification-success-background: #defbe6;
  --notificationsnotification-warning-background: #fcf4d6;
  --notificationssupport-error-border: #da1e284d;
  --notificationssupport-info-border: #0043ce4d;
  --notificationssupport-success-border: #24a1484d;
  --notificationssupport-warning-border: #f1c21b4d;
  --selected-inverse-inner-border-all: ;
  --selected-inverse-inner-border-bottom: ;
  --selected-inverse-inner-border-left: ;
  --selected-inverse-inner-border-right: ;
  --selected-inverse-inner-border-top: ;
  --selected-inverse-outer-border-all: 0px 0px 0px 3px #0f62fe;
  --selected-inverse-outer-border-bottom: 0px 3px 0px 0px #0f62fe;
  --selected-inverse-outer-border-left: -3px 0px 0px 0px #0f62fe;
  --selected-inverse-outer-border-right: 3px 0px 0px 0px #0f62fe;
  --selected-inverse-outer-border-top: 0px -3px 0px 0px #0f62fe;
  --shadows-menu: 0px 2px 6px 0px #0000004d;
  --skeletonskeleton-background: var(--theme-miscellaneous-skeleton-background);
  --skeletonskeleton-element: var(--theme-miscellaneous-skeleton-element);
  --spacing-64px-4rem-spacing-10: 64px;
  --spacing-96px-6rem-spacing-12: 96px;
  --support-error-inner-border-all: ;
  --support-error-outer-border-all: 0px 0px 0px 2px #da1e28;
  --support-inversesupport-error-inverse: var(--theme-support-support-error-inverse);
  --support-inversesupport-info-inverse: var(--theme-support-support-info-inverse);
  --support-inversesupport-success-inverse: var(--theme-support-support-success-inverse);
  --support-inversesupport-warning-inverse: var(--theme-support-support-warning-inverse);
  --supportsupport-caution-major: #ff832b;
  --supportsupport-caution-minor: #f1c21b;
  --supportsupport-error: var(--theme-support-support-error);
  --supportsupport-info: var(--theme-support-support-info);
  --supportsupport-success: var(--theme-support-support-success);
  --supportsupport-undefined: #8a3ffc;
  --supportsupport-warning: var(--theme-support-support-warning);
  --tagblue-background: var(--theme-tag-blue-tag-background-blue);
  --tagblue-hover: var(--theme-tag-blue-tag-hover-blue);
  --tagblue-text: var(--theme-tag-blue-tag-color-blue);
  --tagcool-gray-background: var(--theme-tag-cool-gray-tag-background-cool-gray);
  --tagcool-gray-hover: var(--theme-tag-cool-gray-tag-hover-gray);
  --tagcool-gray-text: var(--theme-tag-cool-gray-tag-color-cool-gray);
  --tagcyan-background: var(--theme-tag-cyan-tag-background-cyan);
  --tagcyan-hover: var(--theme-tag-cyan-tag-hover-cyan);
  --tagcyan-text: var(--theme-tag-cyan-tag-color-cyan);
  --taggray-background: var(--theme-tag-gray-tag-background-gray);
  --taggray-hover: var(--theme-tag-gray-tag-hover-gray);
  --taggray-text: var(--theme-tag-gray-tag-color-gray);
  --taggreen-background: var(--theme-tag-green-tag-background-green);
  --taggreen-hover: var(--theme-tag-green-tag-hover-green);
  --taggreen-text: var(--theme-tag-green-tag-color-green);
  --tagmagenta-background: var(--theme-tag-magenta-tag-background-magenta);
  --tagmagenta-hover: var(--theme-tag-magenta-tag-hover-magenta);
  --tagmagenta-text: var(--theme-tag-magenta-tag-color-magenta);
  --tagpurple-background: var(--theme-tag-purple-tag-background-purple);
  --tagpurple-hover: var(--theme-tag-purple-tag-hover-purple);
  --tagpurple-text: var(--theme-tag-purple-tag-color-purple);
  --tagred-background: var(--theme-tag-red-tag-background-red);
  --tagred-hover: var(--theme-tag-red-tag-hover-red);
  --tagred-text: var(--theme-tag-red-tag-color-red);
  --tagteal-background: var(--theme-tag-teal-tag-background-teal);
  --tagteal-hover: var(--theme-tag-teal-tag-hover-teal);
  --tagteal-text: var(--theme-tag-teal-tag-color-teal);
  --tagwarm-gray-background: var(--theme-tag-warm-gray-tag-background-warm-gray);
  --tagwarm-gray-hover: var(--theme-tag-warm-gray-tag-hover-gray);
  --tagwarm-gray-text: var(--theme-tag-warm-gray-tag-color-warm-gray);
  --texttext-disabled: var(--theme-text-text-disabled);
  --texttext-error: var(--theme-text-text-error);
  --texttext-helper: var(--theme-text-text-helper);
  --texttext-inverse: var(--theme-text-text-inverse);
  --texttext-on-color: var(--theme-text-text-on-color);
  --texttext-on-color-disabled: var(--theme-text-text-on-color-disabled);
  --texttext-placeholder: var(--theme-text-text-placeholder);
  --texttext-primary: var(--theme-text-text-primary);
  --texttext-secondary: var(--theme-text-text-secondary);
  --theme-background-background: var(--colors-default-neutral-white);
  --theme-background-background-active: #8d8d8d80;
  --theme-background-background-hover: #8d8d8d1f;
  --theme-background-background-selected: #8d8d8d33;
  --theme-background-background-selected-hover: #8d8d8d52;
  --theme-field-field-02: var(--colors-default-neutral-white);
  --theme-focus-focus-inset: var(--colors-default-neutral-white);
  --theme-focus-focus-inverse: var(--colors-default-neutral-white);
  --theme-icon-icon-disabled: #16161640;
  --theme-icon-icon-inverse: var(--colors-default-neutral-white);
  --theme-icon-icon-on-color: var(--colors-default-neutral-white);
  --theme-inverse-black-white-black-in-light-themes: var(--colors-default-neutral-black);
  --theme-layer-layer-02: var(--colors-default-neutral-white);
  --theme-miscellaneous-overlay: #16161680;
  --theme-text-text-disabled: #16161640;
  --theme-text-text-inverse: var(--colors-default-neutral-white);
  --theme-text-text-on-color: var(--colors-default-neutral-white);
  --transparenttransparent: #fff;
  --variable-collection-blink-green: #9eec8f;
}

[data-theme-mode="white-theme"], [data-theme-mode="gray-10-theme"] {
  --theme-background-background-active: #8d8d8d80;
  --theme-background-background-hover: #8d8d8d1f;
  --theme-background-background-selected: #8d8d8d33;
  --theme-icon-icon-disabled: #16161640;
  --theme-inverse-black-white-black-in-light-themes: var(--colors-default-neutral-black);
  --theme-miscellaneous-overlay: #16161680;
  --theme-text-text-disabled: #16161640;
}

[data-theme-mode="gray-90-theme"], [data-theme-mode="gray-100-theme"] {
  --theme-background-background-active: #8d8d8d66;
  --theme-background-background-hover: #8d8d8d29;
  --theme-background-background-selected: #8d8d8d3d;
  --theme-icon-icon-disabled: #f4f4f440;
  --theme-inverse-black-white-black-in-light-themes: var(--colors-default-neutral-white);
  --theme-miscellaneous-overlay: #161616b3;
  --theme-text-text-disabled: #f4f4f440;
}

.icon-menu-icon {
  width: 24px;
  height: 24px;
}

.icon-menu-icon .icon {
  height: 24px;
  position: relative;
}

.icon-menu-icon .subtract {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.icon-menu-icon.support, .icon-menu-icon.wallet, .icon-menu-icon.calendar, .icon-menu-icon.stroke, .icon-menu-icon.address, .icon-menu-icon.refer-a-friend, .icon-menu-icon.notification {
  background-size: 100% 100%;
}

.icon-menu-icon.notification.theme {
  background-image: url("icon-8.79ae548a.svg");
}

.icon-menu-icon.black.calendar {
  background-image: url("calendar-4.dbb013f3.svg");
}

.icon-menu-icon.soft-gray.calendar {
  background-image: url("calendar-1.547e3312.svg");
}

.icon-menu-icon.support.grey {
  background-image: url("icon-21.506b62a5.svg");
}

.icon-menu-icon.grey.calendar {
  background-image: url("icon-type-light-icon-name-calendar-size-24px.6654ac7a.svg");
}

.icon-menu-icon.support.soft-gray {
  background-image: url("icon-20.f0fa48fb.svg");
}

.icon-menu-icon.wallet.black {
  background-image: url("icon-24px-fill-2.ca19c192.svg");
}

.icon-menu-icon.refer-a-friend.soft-gray {
  background-image: url("icon-15.0e682faa.svg");
}

.icon-menu-icon.theme.address {
  background-image: url("icon-3.2ffb4374.svg");
}

.icon-menu-icon.address.soft-gray {
  background-image: url("icon-6.3eb87688.svg");
}

.icon-menu-icon.support.black {
  background-image: url("icon-19.c1ec54bd.svg");
}

.icon-menu-icon.white.wallet {
  background-image: url("icon-24px-fill-1.7909672d.svg");
}

.icon-menu-icon.offers.grey {
  background-image: url("icon-14.4675de69.svg");
}

.icon-menu-icon.white.address {
  background-image: url("icon-4.c7e3afec.svg");
}

.icon-menu-icon.notification.soft-gray {
  background-image: url("icon-11.fb636804.svg");
}

.icon-menu-icon.wallet.grey {
  background-image: url("icon-type-light-icon-name-wallet-size-24px.64019d93.svg");
}

.icon-menu-icon.theme.refer-a-friend {
  background-image: url("icon.9e683782.svg");
}

.icon-menu-icon.grey.address {
  background-image: url("icon-7.0d82ef6a.svg");
}

.icon-menu-icon.theme.wallet {
  background-image: url("icon-24px-fill.b3e35cfe.svg");
}

.icon-menu-icon.notification.black {
  background-image: url("icon-10.826e9e61.svg");
}

.icon-menu-icon.theme.calendar {
  background-image: url("calendar-2.0d069ea7.svg");
}

.icon-menu-icon.white.support {
  background-image: url("icon-18.cb9814cd.svg");
}

.icon-menu-icon.white.refer-a-friend {
  background-image: url("icon-1.a34b286c.svg");
}

.icon-menu-icon.grey.refer-a-friend {
  background-image: url("icon-16.b15e9578.svg");
}

.icon-menu-icon.wallet.soft-gray {
  background-image: url("icon-24px-stroke-1.332406be.svg");
}

.icon-menu-icon.white.calendar {
  background-image: url("calendar-3.ed2ef099.svg");
}

.icon-menu-icon.support.theme {
  background-image: url("icon-17.72e3cde4.svg");
}

.icon-menu-icon.offers.soft-gray {
  background-image: url("icon-13.404ea0cd.svg");
}

.icon-menu-icon.white.notification {
  background-image: url("icon-9.cd9cfda3.svg");
}

.icon-menu-icon.notification.grey {
  background-image: url("icon-type-light-icon-name-notification-size-24px.e2e0de27.svg");
}

.icon-menu-icon.refer-a-friend.black {
  background-image: url("icon-2.80db6c00.svg");
}

.icon-menu-icon.address.black {
  background-image: url("icon-5.e47bd060.svg");
}

body {
  font-family: Figtree, sans-serif;
}

.desktop {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 1440px;
  display: flex;
}

.desktop .div {
  background-color: #fff;
  width: 1440px;
  height: 3904px;
  position: relative;
  overflow: hidden;
}

.desktop .overlap {
  width: 1440px;
  height: 3904px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .rectangle {
  background-color: #f5f5f5;
  width: 1440px;
  height: 3904px;
  position: absolute;
  top: 89px;
  left: 0;
}

.desktop .group {
  width: 501px;
  height: 188px;
  position: absolute;
  top: 2054px;
  left: 742px;
}

.desktop .frame {
  align-items: flex-start;
  gap: 16.84px;
  width: 501px;
  height: 188px;
  display: flex;
  position: relative;
}

.desktop .frame-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 16.84px;
  display: inline-flex;
  position: relative;
}

.desktop .div-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.desktop .frame-2 {
  background-color: #fff;
  border-radius: 5.61px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8.42px;
  padding: 16.84px;
  display: inline-flex;
  position: relative;
  box-shadow: 4.11px 3.08px 9.25px #0000000d;
}

.desktop .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 14.04px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-4 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 11.23px;
  display: inline-flex;
  position: relative;
}

.desktop .company-logo {
  border: .7px solid;
  border-color: var(--blinkgrey-20);
  background-color: #fff;
  border-radius: 23.86px;
  width: 47.72px;
  height: 47.72px;
  position: relative;
}

.desktop .icons {
  width: 41px;
  height: 41px;
  position: relative;
  top: 3px;
  left: 3px;
}

.desktop .coinbase {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.desktop .rectangle-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2.81px;
  display: inline-flex;
  position: relative;
}

.desktop .rectangle-2 {
  background-color: #e9edf1;
  width: 82.11px;
  height: 17.55px;
  position: relative;
}

.desktop .img {
  width: 82.11px;
  height: 10.53px;
  position: relative;
}

.desktop .frame-5 {
  opacity: 0;
  flex: none;
  align-items: flex-start;
  gap: 3.51px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-6 {
  flex: none;
  align-items: flex-start;
  gap: 3.51px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-7 {
  background-color: var(--bg);
  border-radius: 17.55px;
  flex: none;
  align-items: flex-start;
  gap: 7.02px;
  padding: 2.81px 5.61px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper {
  color: var(--blinkblack);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.7px;
  font-size: 8.4px;
  font-weight: 500;
  line-height: 9.3px;
  position: relative;
}

.desktop .in-process {
  background-color: var(--blink-green);
  border-radius: 103.6px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10.36px;
  padding: 4.14px 8.29px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper-2 {
  color: var(--blink-black);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.04px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  position: relative;
}

.desktop .frame-8 {
  background-color: #fff;
  border-radius: 5.61px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8.42px;
  padding: 16.84px;
  display: inline-flex;
  position: relative;
  box-shadow: 3.08px 4.11px 9.25px #0000000d;
}

.desktop .apple {
  width: 17px;
  height: 22px;
  position: absolute;
  top: 8px;
  left: 12px;
}

.desktop .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-right: -.35px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-10 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 155.14px;
  display: flex;
  position: relative;
}

.desktop .frame-11 {
  background-color: #fff;
  border-radius: 5.61px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8.42px;
  width: 155.14px;
  padding: 16.84px;
  display: flex;
  position: relative;
  box-shadow: 3.08px 4.11px 9.25px #0000000d;
}

.desktop .amazon {
  width: 24px;
  height: 25px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.desktop .in-process-2 {
  background-color: var(--notificationsnotification-warning-background);
  border-radius: 103.6px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10.36px;
  padding: 4.14px 8.29px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper-3 {
  color: #9d7a00;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.04px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  position: relative;
}

.desktop .in-process-3 {
  cursor: pointer;
  cursor: pointer;
  background-color: #535763;
  border-radius: 173.66px;
  justify-content: center;
  align-items: center;
  gap: 17.37px;
  padding: 16px 37px;
  transition: box-shadow .3s;
  display: inline-flex;
  position: absolute;
  top: 409px;
  left: 589px;
}

.desktop .in-process-3.fix-position {
  position: static;
}

.desktop .in-process-3:hover {
  box-shadow: 0 0 20px #9eec8f;
}

.desktop .text-wrapper-4 {
  color: var(--variable-collection-blink-green);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.74px;
  font-size: 23.5px;
  font-weight: 600;
  line-height: 30.6px;
  position: relative;
}

.desktop .rectangle-3 {
  background-color: #fff;
  border-radius: 17.08px;
  width: 882px;
  height: 846px;
  position: absolute;
  top: 570px;
  left: 285px;
  box-shadow: 9px 6px 24px #00000026;
}

.desktop .rectangle-4 {
  background-color: #fff;
  width: 882px;
  height: 68px;
  position: absolute;
  top: 620px;
  left: 285px;
  box-shadow: 0 1.37px 2.73px 1.37px #0000000d;
}

.desktop .rectangle-5 {
  background-color: var(--blink-black);
  border-radius: 17.08px 17.08px 0 0;
  width: 882px;
  height: 50px;
  position: absolute;
  top: 570px;
  left: 285px;
}

.desktop .ellipse {
  background-color: #fff;
  border-radius: 8.54px;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 588px;
  left: 303px;
}

.desktop .ellipse-2 {
  background-color: #fff;
  border-radius: 8.54px;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 588px;
  left: 333px;
}

.desktop .ellipse-3 {
  background-color: #fff;
  border-radius: 8.54px;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 588px;
  left: 364px;
}

.desktop .top {
  background-color: #fff;
  border-radius: 17.08px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16.39px;
  width: 235px;
  height: 277px;
  padding: 16.39px 21.86px;
  display: flex;
  position: absolute;
  top: 741px;
  left: 168px;
  box-shadow: 17.08px 17.08px 37px #00000040;
}

.desktop .frame-12 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5.46px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .avatar {
  background-color: var(--coloraccent-05);
  border: .68px solid;
  border-color: var(--accessibilitywhite);
  border-radius: 32.79px;
  width: 35.52px;
  height: 35.52px;
  position: relative;
  overflow: hidden;
}

.desktop .element {
  object-fit: cover;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .user {
  flex: 1;
  align-items: flex-end;
  gap: 8.2px;
  display: flex;
  position: relative;
}

.desktop .name {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 2.73px;
  display: flex;
  position: relative;
}

.desktop .text-wrapper-5 {
  color: var(--black);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.68px;
  font-size: 9.6px;
  font-weight: 400;
  line-height: 10.5px;
  position: relative;
}

.desktop .text-wrapper-6 {
  color: var(--black);
  letter-spacing: 0;
  align-self: stretch;
  font-size: 13.7px;
  font-weight: 600;
  line-height: 15px;
  position: relative;
}

.desktop .frame-13 {
  flex: none;
  align-items: center;
  gap: 8.2px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper-7 {
  color: var(--theme-inverse-black-white-black-in-light-themes);
  letter-spacing: 0;
  width: 33.47px;
  margin-top: -.68px;
  font-size: 10.9px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.desktop .frame-14 {
  flex: none;
  align-items: center;
  gap: 2.73px;
  display: inline-flex;
  position: relative;
}

.desktop .ellipse-4 {
  background-color: var(--blinkgreen);
  border-radius: 4.17px;
  width: 8.35px;
  height: 8.35px;
  position: relative;
}

.desktop .text-wrapper-8 {
  color: var(--theme-inverse-black-white-black-in-light-themes);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.68px;
  font-size: 10.9px;
  font-weight: 400;
  line-height: 12px;
  position: relative;
}

.desktop .line {
  width: 191.27px;
  height: 1.37px;
  position: relative;
}

.desktop .frame-15 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.46px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .rectangle-6 {
  background-color: #fff;
  border-radius: 17.08px;
  width: 191.27px;
  height: 147.55px;
  position: relative;
}

.desktop .frame-16 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5.46px;
  width: 191.27px;
  height: 148px;
  display: flex;
  position: absolute;
  top: 853px;
  left: 120px;
  box-shadow: 20.49px 13.66px 20.49px #00000040;
}

.desktop .nav-item {
  background-color: var(--blinkblack);
  border-radius: 5.46px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8.2px;
  width: 100%;
  padding: 8.2px;
  display: flex;
  position: relative;
}

.desktop .left {
  flex: 1;
  align-items: center;
  gap: 12.3px;
  display: flex;
  position: relative;
}

.desktop .icon-instance-node {
  width: 16.39px !important;
  height: 16.39px !important;
  position: relative !important;
}

.desktop .text-wrapper-9 {
  color: #fff;
  letter-spacing: 0;
  flex: 1;
  font-size: 13.7px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.desktop .left-wrapper {
  background-color: #fff;
  border-radius: 5.46px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8.2px;
  width: 100%;
  padding: 8.2px;
  display: flex;
  position: relative;
}

.desktop .icon-menu-icon-instance {
  background-image: url("icon-24px-stroke.26861451.svg") !important;
  width: 16.39px !important;
  height: 16.39px !important;
  position: relative !important;
}

.desktop .text-wrapper-10 {
  color: var(--blinkgrey-80);
  letter-spacing: 0;
  flex: 1;
  font-size: 13.7px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.desktop .present-desk-ex {
  width: 108px;
  height: 44px;
  position: absolute;
  top: 633px;
  left: 300px;
}

.desktop .group-2 {
  background-color: #fff;
  border-radius: 17.08px;
  width: 576px;
  height: 278px;
  position: absolute;
  top: 664px;
  left: 462px;
  box-shadow: 5px 10px 19px 5px #0000001a;
}

.desktop .frame-17 {
  align-items: center;
  gap: 5.46px;
  display: inline-flex;
  position: absolute;
  top: 30px;
  left: 42px;
}

.desktop .text-wrapper-11 {
  color: var(--black);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.68px;
  font-size: 28.7px;
  font-weight: 600;
  line-height: 31.6px;
  position: relative;
}

.desktop .frame-18 {
  align-items: flex-start;
  gap: 16.39px;
  display: inline-flex;
  position: absolute;
  top: 79px;
  left: 36px;
}

.desktop .frame-19 {
  align-items: flex-start;
  gap: 16.39px;
  width: 156.86px;
  display: flex;
  position: relative;
}

.desktop .frame-20 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-right: -.07px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-21 {
  background-color: #fff;
  border-radius: 5.46px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 13.66px;
  padding: 16.39px;
  display: inline-flex;
  position: relative;
  box-shadow: 0 1.37px 4.1px #0000004c;
}

.desktop .frame-22 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 13.66px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-23 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 10.93px;
  display: inline-flex;
  position: relative;
}

.desktop .in-process-4 {
  background-color: var(--notificationsnotification-warning-background);
  border-radius: 100.84px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10.08px;
  padding: 4.03px 8.07px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper-12 {
  color: #9d7a00;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.01px;
  font-size: 13.7px;
  font-weight: 600;
  line-height: 17.8px;
  position: relative;
}

.desktop .frame-24 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2.73px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-25 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.83px;
  display: inline-flex;
  position: relative;
}

.desktop .rectangle-7 {
  background-color: #e9edf1;
  width: 79.92px;
  height: 17.08px;
  position: relative;
}

.desktop .rectangle-8 {
  background-color: #e9edf1;
  width: 47.13px;
  height: 10.25px;
  position: relative;
}

.desktop .frame-26 {
  opacity: 0;
  flex: none;
  align-items: flex-start;
  gap: 2.31px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-27 {
  flex: none;
  align-items: flex-start;
  gap: 2.31px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-28 {
  background-color: var(--bg);
  border-radius: 11.54px;
  flex: none;
  align-items: flex-start;
  gap: 4.62px;
  padding: 1.85px 3.69px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper-13 {
  color: var(--blinkblack);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.46px;
  font-size: 5.5px;
  font-weight: 500;
  line-height: 6.1px;
  position: relative;
}

.desktop .button {
  all: unset;
  background-color: var(--black);
  box-sizing: border-box;
  border-radius: 57.33px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .button-content {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 4.59px 9.17px;
  display: flex;
  position: relative;
}

.desktop .button-2 {
  all: unset;
  box-sizing: border-box;
  color: var(--blinkwhite);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-size: 10.3px;
  font-weight: 400;
  line-height: 11.4px;
  position: relative;
}

.desktop .frame-29 {
  background-color: #fff;
  border-radius: 5.46px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 13.66px;
  width: 156.86px;
  padding: 16.39px;
  display: flex;
  position: relative;
  box-shadow: 0 1.37px 4.1px #0000004c;
}

.desktop .in-process-5 {
  background-color: #9eec8f;
  border-radius: 100.84px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10.08px;
  padding: 4.03px 8.07px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper-14 {
  color: var(--blink-black);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.01px;
  font-size: 13.7px;
  font-weight: 600;
  line-height: 17.8px;
  position: relative;
}

.desktop .frame-30 {
  opacity: 0;
  align-items: flex-start;
  gap: 2.31px;
  height: 6.83px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-31 {
  flex: none;
  align-items: flex-start;
  gap: 2.31px;
  margin-bottom: -2.86px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-32 {
  flex: none;
  align-items: flex-start;
  gap: 10.93px;
  width: 112.03px;
  padding: 4.1px 0;
  display: flex;
  position: relative;
}

.desktop .line-2 {
  width: 1px;
  height: 16.39px;
  position: relative;
}

.desktop .frame-33 {
  background-color: #fff;
  border-radius: 5.46px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 32.79px;
  width: 156.86px;
  height: 162.45px;
  padding: 16.39px;
  display: flex;
  position: relative;
  box-shadow: 0 1.37px 4.1px #0000004c;
}

.desktop .frame-34 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10.93px;
  width: 112.03px;
  display: flex;
  position: relative;
}

.desktop .icon-solid-plus-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 5.46px;
  display: inline-flex;
  position: relative;
}

.desktop .icon-solid-plus {
  width: 27.32px !important;
  height: 27.32px !important;
  position: relative !important;
}

.desktop .group-3 {
  background-color: #fff;
  border-radius: 17.08px;
  width: 576px;
  height: 300px;
  position: absolute;
  top: 895px;
  left: 364px;
  box-shadow: 14px 9px 34px 8px #0003;
}

.desktop .frame-35 {
  align-items: center;
  gap: 5.46px;
  display: inline-flex;
  position: absolute;
  top: 35px;
  left: 42px;
}

.desktop .frame-36 {
  align-items: flex-start;
  gap: 16.39px;
  display: inline-flex;
  position: absolute;
  top: 84px;
  left: 36px;
}

.desktop .frame-37 {
  align-items: flex-start;
  gap: 16.39px;
  width: 157.54px;
  display: flex;
  position: relative;
}

.desktop .frame-38 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-right: -.39px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-39 {
  background-color: #fff;
  border-radius: 5.46px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.83px;
  padding: 16.39px;
  display: inline-flex;
  position: relative;
  box-shadow: 0 1.37px 4.1px #0000004c;
}

.desktop .icons-wrapper {
  border: .68px solid;
  border-color: var(--blinkgrey-20);
  background-color: #fff;
  border-radius: 23.23px;
  width: 46.45px;
  height: 46.45px;
  position: relative;
}

.desktop .icons-2 {
  width: 40px;
  height: 40px;
  position: relative;
  top: 3px;
  left: 3px;
}

.desktop .netflix {
  width: 12px;
  height: 22px;
  position: relative;
  top: 8px;
  left: 13px;
}

.desktop .overlap-group {
  height: 22px;
  position: relative;
}

.desktop .rectangle-9 {
  width: 4px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .rectangle-10 {
  width: 4px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 8px;
}

.desktop .vector {
  width: 12px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .rectangle-11 {
  width: 79.92px;
  height: 10.25px;
  position: relative;
}

.desktop .frame-40 {
  opacity: 0;
  flex: none;
  align-items: flex-start;
  gap: 3.42px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-41 {
  flex: none;
  align-items: flex-start;
  gap: 3.42px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-42 {
  background-color: var(--bg);
  border-radius: 17.08px;
  flex: none;
  align-items: flex-start;
  gap: 6.83px;
  padding: 2.73px 5.46px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper-15 {
  color: var(--blinkblack);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.68px;
  font-size: 8.2px;
  font-weight: 500;
  line-height: 9px;
  position: relative;
}

.desktop .frame-43 {
  flex: none;
  align-items: flex-start;
  gap: 16.39px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-44 {
  flex-direction: column;
  align-items: flex-start;
  width: 157.54px;
  display: flex;
  position: relative;
}

.desktop .frame-45 {
  background-color: #fff;
  border-radius: 5.46px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.83px;
  width: 157.54px;
  padding: 16.39px;
  display: flex;
  position: relative;
  box-shadow: 0 1.37px 4.1px #0000004c;
}

.desktop .meta {
  width: 25px;
  height: 16px;
  position: absolute;
  top: 11px;
  left: 7px;
}

.desktop .in-process-6 {
  background-color: var(--blink-green);
  border-radius: 100.84px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10.08px;
  padding: 4.03px 8.07px;
  display: inline-flex;
  position: relative;
}

.desktop .glovo {
  width: 16px;
  height: 26px;
  position: absolute;
  top: 7px;
  left: 12px;
}

.desktop .group-4 {
  background-color: #fff;
  border-radius: 17.08px;
  width: 576px;
  height: 300px;
  position: absolute;
  top: 1177px;
  left: 516px;
  box-shadow: 25.96px 19px 35px 5px #0003;
}

.desktop .frame-46 {
  align-items: center;
  gap: 5.46px;
  display: inline-flex;
  position: absolute;
  top: 29px;
  left: 42px;
}

.desktop .frame-47 {
  align-items: flex-start;
  gap: 16.39px;
  display: inline-flex;
  position: absolute;
  top: 78px;
  left: 36px;
}

.desktop .frame-48 {
  background-color: #fff;
  border-radius: 5.46px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8.2px;
  padding: 16.39px;
  display: inline-flex;
  position: relative;
  box-shadow: 0 1.37px 4.1px #0000004c;
}

.desktop .coinbase-2 {
  width: 25px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 7px;
}

.desktop .apple-2 {
  width: 16px;
  height: 21px;
  position: absolute;
  top: 8px;
  left: 12px;
}

.desktop .frame-49 {
  flex-direction: column;
  align-items: flex-start;
  width: 151px;
  display: flex;
  position: relative;
}

.desktop .frame-50 {
  background-color: #fff;
  border-radius: 5.46px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8.2px;
  width: 151px;
  padding: 16.39px;
  display: flex;
  position: relative;
  box-shadow: 0 1.37px 4.1px #0000004c;
}

.desktop .amazon-2 {
  width: 23px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.desktop .p {
  color: #424242;
  font-size: var(--heading-3-font-size);
  font-style: var(--heading-3-font-style);
  font-weight: var(--heading-3-font-weight);
  letter-spacing: var(--heading-3-letter-spacing);
  line-height: var(--heading-3-line-height);
  text-align: center;
  width: 970px;
  position: absolute;
  top: 192px;
  left: 235px;
}

.desktop .text-wrapper-16 {
  color: #535763e3;
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  text-align: center;
  width: 638px;
  position: absolute;
  top: 321px;
  left: 387px;
}

.desktop .navigation-wrapper {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 24px 40px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 4px #0000001a;
}

.desktop .navigation {
  width: 1360px;
  height: 47px;
  position: relative;
}

.desktop .frame-51 {
  justify-content: space-between;
  align-items: center;
  width: 1360px;
  display: flex;
  position: relative;
}

.desktop .present-desk-v-ex {
  width: 125px;
  height: 51px;
  position: relative;
}

.desktop .frame-52 {
  flex: none;
  align-items: center;
  gap: 48px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper-17, .desktop .text-wrapper-18 {
  color: var(--theme-inverse-black-white-black-in-light-themes);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  transition: text-shadow .3s;
  position: relative;
}

.desktop .text-wrapper-17:hover, .desktop .text-wrapper-18:hover {
  text-shadow: 2px 2px 1px #9eec8f;
}

.desktop .text-wrapper-17 {
  opacity: .5;
}

.desktop .frame-53 {
  flex: none;
  align-items: center;
  gap: 18px;
  display: inline-flex;
  position: relative;
}

.desktop .in-process-7 {
  cursor: pointer;
  background-color: #9eec8f;
  border-radius: 173.66px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 32px;
  height: 31px;
  padding: 16px 14px;
  transition: box-shadow .3s;
  display: inline-flex;
  position: relative;
}

.desktop .in-process-7:hover {
  box-shadow: 0 0 10px #9eec8f;
}

.desktop .text-wrapper-19 {
  color: var(--blink-black);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.desktop .in-process-8 {
  cursor: pointer;
  background-color: #535763;
  border-radius: 173.66px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 32px;
  height: 31px;
  padding: 16px 14px;
  transition: box-shadow .3s;
  display: inline-flex;
  position: relative;
}

.desktop .in-process-8:hover {
  box-shadow: 0 0 10px #9eec8f;
}

.desktop .text-wrapper-20 {
  color: var(--variable-collection-blink-green);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.desktop .vector-2 {
  width: 1440px;
  height: 374px;
  position: absolute;
  top: 1452px;
  left: 0;
}

.desktop .group-5 {
  width: 350px;
  height: 203px;
  position: absolute;
  top: 2046px;
  left: 313px;
}

.desktop .text-wrapper-21 {
  color: var(--blink-black);
  font-size: var(--heading-4-font-size);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  width: 295px;
  position: absolute;
  top: 0;
  left: 2px;
}

.desktop .text-wrapper-22 {
  color: #535763;
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  width: 346px;
  position: absolute;
  top: 93px;
  left: 0;
}

.desktop .group-6 {
  width: 921px;
  height: 215px;
  position: absolute;
  top: 2446px;
  left: 309px;
}

.desktop .AI-auto-resume {
  color: var(--blink-black);
  font-size: var(--heading-4-font-size);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  width: 295px;
  position: absolute;
  top: 12px;
  left: 593px;
}

.desktop .text-wrapper-23 {
  color: #535763;
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  width: 326px;
  position: absolute;
  top: 105px;
  left: 591px;
}

.desktop .group-wrapper {
  width: 447px;
  height: 193px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .group-7 {
  width: 449px;
  height: 193px;
  position: relative;
}

.desktop .frame-54 {
  background-color: var(--blinkwhite);
  border: 2.4px solid;
  border-color: var(--blinkgrey-00);
  border-radius: 9.59px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 447px;
  padding: 18px 20px;
  display: flex;
  position: absolute;
  top: 32px;
  left: 0;
  box-shadow: 14px 9px 23px #0000001a;
}

.desktop .rectangle-12 {
  background-color: #e9edf1;
  width: 407px;
  height: 17px;
  position: relative;
}

.desktop .frame-55 {
  background-color: var(--variable-collection-blink-green);
  border-radius: 9.59px;
  align-items: flex-start;
  gap: 4px;
  padding: 6px 12px;
  display: inline-flex;
  position: absolute;
  top: 62px;
  left: 127px;
  box-shadow: 4px 6px 4px #0000000d;
}

.desktop .mingcute-bling-line {
  width: 24px;
  height: 24px;
  position: relative;
}

.desktop .text-wrapper-24 {
  color: var(--blink-black);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16.8px;
  font-weight: 700;
  line-height: 25.2px;
  position: relative;
}

.desktop .group-8 {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 76px;
  left: 298px;
}

.desktop .text-wrapper-25 {
  color: var(--blinkblack);
  letter-spacing: 0;
  width: 139px;
  height: 25px;
  font-size: 16.8px;
  font-weight: 400;
  line-height: 25.2px;
  position: absolute;
  top: 0;
  left: 13px;
}

.desktop .text-wrapper-26 {
  color: var(--blink-black);
  font-size: var(--heading-4-font-size);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  width: 295px;
  position: absolute;
  top: 2857px;
  left: 317px;
}

.desktop .text-wrapper-27 {
  color: #535763;
  letter-spacing: 0;
  width: 346px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  position: absolute;
  top: 2950px;
  left: 315px;
}

.desktop .overlap-wrapper {
  width: 307px;
  height: 155px;
  position: absolute;
  top: 2874px;
  left: 841px;
}

.desktop .overlap-2 {
  width: 311px;
  height: 155px;
  position: relative;
  left: -4px;
}

.desktop .vector-3 {
  width: 186px;
  height: 44px;
  position: absolute;
  top: 16px;
  left: 83px;
}

.desktop .vector-4 {
  width: 172px;
  height: 27px;
  position: absolute;
  top: 71px;
  left: 83px;
}

.desktop .vector-5 {
  width: 203px;
  height: 29px;
  position: absolute;
  top: 109px;
  left: 83px;
}

.desktop .group-9 {
  width: 98px;
  height: 119px;
  position: absolute;
  top: 29px;
  left: 0;
}

.desktop .group-10 {
  background-color: var(--blink-black);
  border-radius: 2.11px;
  width: 32px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 259px;
}

.desktop .overlap-group-wrapper {
  width: 14px;
  height: 16px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.desktop .overlap-group-2 {
  height: 16px;
  position: relative;
}

.desktop .ellipse-5 {
  width: 14px;
  height: 11px;
  position: absolute;
  top: 5px;
  left: 0;
}

.desktop .ellipse-6 {
  background-color: var(--blink-black);
  border: 1.75px solid #fff;
  border-radius: 4.39px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 0;
  left: 2px;
}

.desktop .rectangle-13 {
  background-color: #fff;
  width: 23px;
  height: 2px;
  position: absolute;
  top: 24px;
  left: 4px;
}

.desktop .rectangle-14 {
  background-color: #fff;
  width: 23px;
  height: 2px;
  position: absolute;
  top: 28px;
  left: 4px;
}

.desktop .rectangle-15 {
  background-color: #fff;
  width: 23px;
  height: 2px;
  position: absolute;
  top: 33px;
  left: 4px;
}

.desktop .group-11 {
  background-color: var(--blink-black);
  border-radius: 2.11px;
  width: 32px;
  height: 40px;
  position: absolute;
  top: 54px;
  left: 230px;
}

.desktop .group-12 {
  background-color: var(--blink-black);
  border-radius: 2.11px;
  width: 32px;
  height: 40px;
  position: absolute;
  top: 115px;
  left: 279px;
}

.desktop .in-process-9 {
  background-color: var(--blink-black);
  border-radius: 100.84px;
  justify-content: center;
  align-items: center;
  gap: 10.08px;
  padding: 4.03px 8.07px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 188px;
}

.desktop .text-wrapper-28 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.01px;
  font-size: 13.7px;
  font-weight: 600;
  line-height: 17.8px;
  position: relative;
}

.desktop .in-process-10 {
  background-color: var(--blink-black);
  border-radius: 100.84px;
  justify-content: center;
  align-items: center;
  gap: 10.08px;
  padding: 4.03px 8.07px;
  display: inline-flex;
  position: absolute;
  top: 62px;
  left: 155px;
}

.desktop .in-process-11 {
  background-color: var(--blink-black);
  border-radius: 100.84px;
  justify-content: center;
  align-items: center;
  gap: 10.08px;
  padding: 4.03px 8.07px;
  display: inline-flex;
  position: absolute;
  top: 120px;
  left: 205px;
}

.desktop .frame-free {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 23px;
  width: 915px;
  display: flex;
  position: absolute;
  top: 3268px;
  left: 286px;
}

.desktop .frame-free .text-1 {
  color: #424242;
  text-align: center;
  font-family: Figtree;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.desktop .frame-free .text-2 {
  color: #424242;
  font-family: Figtree;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}

.desktop .frame-56 {
  box-shadow: var(--shadows-menu);
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  width: 300px;
  padding: 24px;
  transition: box-shadow .3s;
  display: flex;
  position: absolute;
  top: 3511px;
  left: 286px;
}

.desktop .frame-56:hover {
  box-shadow: 0 2px 15px #9eec8f;
}

.desktop .frame-57 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-58 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper-29 {
  color: var(--black);
  font-size: var(--heading-5-font-size);
  font-style: var(--heading-5-font-style);
  font-weight: var(--heading-5-font-weight);
  letter-spacing: var(--heading-5-letter-spacing);
  line-height: var(--heading-5-line-height);
  width: 164px;
  margin-top: -1px;
  position: relative;
}

.desktop .text-wrapper-30 {
  font-size: var(--heading-4-font-size);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  width: 164px;
  position: relative;
}

.desktop .text-wrapper-line-through {
  color: #999;
  text-decoration: line-through;
  -webkit-text-decoration-color: var(--black);
  text-decoration-color: var(--black);
}

.desktop .text-wrapper-free {
  color: var(--black);
  margin-left: 8px;
}

.desktop .frame-59 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 18px;
  margin-right: -8px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-60 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-61 {
  flex: none;
  align-items: center;
  gap: 3px;
  display: inline-flex;
  position: relative;
}

.desktop .ellipse-wrapper {
  width: 22px;
  height: 22px;
  position: relative;
}

.desktop .ellipse-7 {
  background-color: #9eec8f;
  border-radius: 3px;
  width: 6px;
  height: 6px;
  position: relative;
  top: 8px;
  left: 8px;
}

.desktop .div-2 {
  color: #535763;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.desktop .span {
  font-size: var(--body-2-bold-font-size);
  font-style: var(--body-2-bold-font-style);
  font-weight: var(--body-2-bold-font-weight);
  letter-spacing: var(--body-2-bold-letter-spacing);
  line-height: var(--body-2-bold-line-height);
}

.desktop .text-wrapper-31 {
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
}

.desktop .frame-62 {
  background-color: #9eec8ff2;
  border-radius: 4px;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  padding: 6px 12px;
  display: inline-flex;
  position: relative;
}

.desktop .fluent-star-emphasis {
  width: 21px;
  height: 21px;
  position: relative;
}

.desktop .text-wrapper-32 {
  color: var(--blink-black);
  letter-spacing: 0;
  white-space: nowrap;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  position: relative;
}

.desktop .frame-63 {
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  width: 300px;
  padding: 24px;
  transition: box-shadow .3s;
  display: flex;
  position: absolute;
  top: 3511px;
  left: 606px;
  box-shadow: 0 2px 6px #0000001a;
}

.desktop .frame-63:hover {
  box-shadow: 0 2px 15px #9eec8f;
}

.desktop .text-wrapper-33 {
  color: #23272d;
  font-size: var(--heading-5-font-size);
  font-style: var(--heading-5-font-style);
  font-weight: var(--heading-5-font-weight);
  letter-spacing: var(--heading-5-letter-spacing);
  line-height: var(--heading-5-line-height);
  width: 164px;
  margin-top: -1px;
  position: relative;
}

.desktop .text-wrapper-34 {
  font-size: var(--heading-4-font-size);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  width: 164px;
  position: relative;
}

.desktop .frame-64 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 18px;
  margin-right: -5px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-65 {
  box-shadow: var(--shadows-menu);
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  width: 300px;
  padding: 24px;
  transition: box-shadow .3s;
  display: flex;
  position: absolute;
  top: 3511px;
  left: 926px;
}

.desktop .frame-65:hover {
  box-shadow: 0 2px 15px #9eec8f;
}

.desktop .frame-66 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 18px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper-35 {
  color: #535763;
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.5px;
  position: relative;
}

.desktop .element-job-matches-day {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.desktop .text-wrapper-36 {
  color: #535763;
  font-size: var(--body-2-bold-font-size);
  font-style: var(--body-2-bold-font-style);
  font-weight: var(--body-2-bold-font-weight);
  letter-spacing: var(--body-2-bold-letter-spacing);
  line-height: var(--body-2-bold-line-height);
}

.desktop .text-wrapper-37 {
  color: #535763;
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
}

.desktop .group-13 {
  width: 80px;
  height: 69px;
  position: absolute;
  top: 1134px;
  left: 1142px;
}

.desktop .rectangle-16 {
  background-color: #d9d9d9;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 3962px;
  left: -3386px;
}

/*# sourceMappingURL=index.6b907703.css.map */
