body {
  font-family: Figtree, sans-serif;
}

.desktop {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1440px;
}

.desktop .div {
  background-color: #ffffff;
  height: 3904px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.desktop .overlap {
  height: 3904px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.desktop .rectangle {
  background-color: #f5f5f5;
  height: 3904px;
  left: 0;
  position: absolute;
  top: 89px;
  width: 1440px;
}

.desktop .group {
  height: 188px;
  left: 742px;
  position: absolute;
  top: 2054px;
  width: 501px;
}

.desktop .frame {
  align-items: flex-start;
  display: flex;
  gap: 16.84px;
  height: 188px;
  position: relative;
  width: 501px;
}

.desktop .frame-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16.84px;
  position: relative;
}

.desktop .div-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.desktop .frame-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.61px;
  box-shadow: 4.11px 3.08px 9.25px #0000000d;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.42px;
  padding: 16.84px;
  position: relative;
}

.desktop .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14.04px;
  position: relative;
}

.desktop .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.23px;
  justify-content: center;
  position: relative;
}

.desktop .company-logo {
  background-color: #ffffff;
  border: 0.7px solid;
  border-color: var(--blinkgrey-20);
  border-radius: 23.86px;
  height: 47.72px;
  position: relative;
  width: 47.72px;
}

.desktop .icons {
  height: 41px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 41px;
}

.desktop .coinbase {
  height: 25px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 25px;
}

.desktop .rectangle-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.81px;
  position: relative;
}

.desktop .rectangle-2 {
  background-color: #e9edf1;
  height: 17.55px;
  position: relative;
  width: 82.11px;
}

.desktop .img {
  height: 10.53px;
  position: relative;
  width: 82.11px;
}

.desktop .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.51px;
  opacity: 0;
  position: relative;
}

.desktop .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.51px;
  position: relative;
}

.desktop .frame-7 {
  align-items: flex-start;
  background-color: var(--bg);
  border-radius: 17.55px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.02px;
  padding: 2.81px 5.61px;
  position: relative;
}

.desktop .text-wrapper {
  color: var(--blinkblack);
  font-size: 8.4px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 9.3px;
  margin-top: -0.7px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.desktop .in-process {
  align-items: center;
  background-color: var(--blink-green);
  border-radius: 103.6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.36px;
  justify-content: center;
  padding: 4.14px 8.29px;
  position: relative;
}

.desktop .text-wrapper-2 {
  color: var(--blink-black);

  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18.2px;
  margin-top: -1.04px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .frame-8 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.61px;
  box-shadow: 3.08px 4.11px 9.25px #0000000d;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.42px;
  padding: 16.84px;
  position: relative;
}

.desktop .apple {
  height: 22px;
  left: 12px;
  position: absolute;
  top: 8px;
  width: 17px;
}

.desktop .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-right: -0.35px;
  position: relative;
}

.desktop .frame-10 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 155.14px;
}

.desktop .frame-11 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.61px;
  box-shadow: 3.08px 4.11px 9.25px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.42px;
  padding: 16.84px;
  position: relative;
  width: 155.14px;
}

.desktop .amazon {
  height: 25px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 24px;
}

.desktop .in-process-2 {
  align-items: center;
  background-color: var(--notificationsnotification-warning-background);
  border-radius: 103.6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.36px;
  justify-content: center;
  padding: 4.14px 8.29px;
  position: relative;
  /* cursor: pointer; */
}

.desktop .text-wrapper-3 {
  color: #9d7a00;

  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18.2px;
  margin-top: -1.04px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .in-process-3 {
  align-items: center;
  background-color: #535763;
  border-radius: 173.66px;
  display: inline-flex;
  gap: 17.37px;
  justify-content: center;
  left: 589px;
  padding: 16px 37px;
  position: absolute;
  top: 409px;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  cursor: pointer;
}

.desktop .in-process-3.fix-position {
  position: static;
}

.desktop .in-process-3:hover {
  box-shadow: 0 0 20px #9EEC8F;
}

.desktop .text-wrapper-4 {
  color: var(--variable-collection-blink-green);

  font-size: 23.5px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30.6px;
  margin-top: -1.74px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.desktop .rectangle-3 {
  background-color: #ffffff;
  border-radius: 17.08px;
  box-shadow: 9px 6px 24px #00000026;
  height: 846px;
  left: 285px;
  position: absolute;
  top: 570px;
  width: 882px;
}

.desktop .rectangle-4 {
  background-color: #ffffff;
  box-shadow: 0px 1.37px 2.73px 1.37px #0000000d;
  height: 68px;
  left: 285px;
  position: absolute;
  top: 620px;
  width: 882px;
}

.desktop .rectangle-5 {
  background-color: var(--blink-black);
  border-radius: 17.08px 17.08px 0px 0px;
  height: 50px;
  left: 285px;
  position: absolute;
  top: 570px;
  width: 882px;
}

.desktop .ellipse {
  background-color: #ffffff;
  border-radius: 8.54px;
  height: 17px;
  left: 303px;
  position: absolute;
  top: 588px;
  width: 17px;
}

.desktop .ellipse-2 {
  background-color: #ffffff;
  border-radius: 8.54px;
  height: 17px;
  left: 333px;
  position: absolute;
  top: 588px;
  width: 17px;
}

.desktop .ellipse-3 {
  background-color: #ffffff;
  border-radius: 8.54px;
  height: 17px;
  left: 364px;
  position: absolute;
  top: 588px;
  width: 17px;
}

.desktop .top {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 17.08px;
  box-shadow: 17.08px 17.08px 37px #00000040;
  display: flex;
  flex-direction: column;
  gap: 16.39px;
  height: 277px;
  left: 168px;
  padding: 16.39px 21.86px;
  position: absolute;
  top: 741px;
  width: 235px;
}

.desktop .frame-12 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.46px;
  position: relative;
  width: 100%;
}

.desktop .avatar {
  background-color: var(--coloraccent-05);
  border: 0.68px solid;
  border-color: var(--accessibilitywhite);
  border-radius: 32.79px;
  height: 35.52px;
  overflow: hidden;
  position: relative;
  width: 35.52px;
}

.desktop .element {
  height: 36px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 36px;
}

.desktop .user {
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8.2px;
  position: relative;
}

.desktop .name {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.73px;
  position: relative;
}

.desktop .text-wrapper-5 {
  align-self: stretch;
  color: var(--black);

  font-size: 9.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 10.5px;
  margin-top: -0.68px;
  position: relative;
}

.desktop .text-wrapper-6 {
  align-self: stretch;
  color: var(--black);

  font-size: 13.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  position: relative;
}

.desktop .frame-13 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.2px;
  position: relative;
}

.desktop .text-wrapper-7 {
  color: var(--theme-inverse-black-white-black-in-light-themes);

  font-size: 10.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -0.68px;
  position: relative;
  width: 33.47px;
}

.desktop .frame-14 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.73px;
  position: relative;
}

.desktop .ellipse-4 {
  background-color: var(--blinkgreen);
  border-radius: 4.17px;
  height: 8.35px;
  position: relative;
  width: 8.35px;
}

.desktop .text-wrapper-8 {
  color: var(--theme-inverse-black-white-black-in-light-themes);

  font-size: 10.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .line {
  height: 1.37px;
  position: relative;
  width: 191.27px;
}

.desktop .frame-15 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.46px;
  position: relative;
  width: 100%;
}

.desktop .rectangle-6 {
  background-color: #ffffff;
  border-radius: 17.08px;
  height: 147.55px;
  position: relative;
  width: 191.27px;
}

.desktop .frame-16 {
  align-items: flex-start;
  box-shadow: 20.49px 13.66px 20.49px #00000040;
  display: flex;
  flex-direction: column;
  gap: 5.46px;
  height: 148px;
  left: 120px;
  position: absolute;
  top: 853px;
  width: 191.27px;
}

.desktop .nav-item {
  align-items: center;
  align-self: stretch;
  background-color: var(--blinkblack);
  border-radius: 5.46px;
  display: flex;
  flex: 0 0 auto;
  gap: 8.2px;
  padding: 8.2px;
  position: relative;
  width: 100%;
}

.desktop .left {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 12.3px;
  position: relative;
}

.desktop .icon-instance-node {
  height: 16.39px !important;
  position: relative !important;
  width: 16.39px !important;
}

.desktop .text-wrapper-9 {
  color: #ffffff;
  flex: 1;

  font-size: 13.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 15px;
  position: relative;
}

.desktop .left-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 5.46px;
  display: flex;
  flex: 0 0 auto;
  gap: 8.2px;
  padding: 8.2px;
  position: relative;
  width: 100%;
}

.desktop .icon-menu-icon-instance {
  background-image: url(../../../static/img/icon-24px-stroke.svg) !important;
  height: 16.39px !important;
  position: relative !important;
  width: 16.39px !important;
}

.desktop .text-wrapper-10 {
  color: var(--blinkgrey-80);
  flex: 1;

  font-size: 13.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 15px;
  position: relative;
}

.desktop .present-desk-ex {
  height: 44px;
  left: 300px;
  position: absolute;
  top: 633px;
  width: 108px;
}

.desktop .group-2 {
  background-color: #ffffff;
  border-radius: 17.08px;
  box-shadow: 5px 10px 19px 5px #0000001a;
  height: 278px;
  left: 462px;
  position: absolute;
  top: 664px;
  width: 576px;
}

.desktop .frame-17 {
  align-items: center;
  display: inline-flex;
  gap: 5.46px;
  left: 42px;
  position: absolute;
  top: 30px;
}

.desktop .text-wrapper-11 {
  color: var(--black);

  font-size: 28.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 31.6px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  gap: 16.39px;
  left: 36px;
  position: absolute;
  top: 79px;
}

.desktop .frame-19 {
  align-items: flex-start;
  display: flex;
  gap: 16.39px;
  position: relative;
  width: 156.86px;
}

.desktop .frame-20 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-right: -0.07px;
  position: relative;
}

.desktop .frame-21 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.46px;
  box-shadow: 0px 1.37px 4.1px #0000004c;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.66px;
  padding: 16.39px;
  position: relative;
}

.desktop .frame-22 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.66px;
  position: relative;
}

.desktop .frame-23 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.93px;
  justify-content: center;
  position: relative;
}

.desktop .in-process-4 {
  align-items: center;
  background-color: var(--notificationsnotification-warning-background);
  border-radius: 100.84px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.08px;
  justify-content: center;
  padding: 4.03px 8.07px;
  position: relative;
  /* cursor: pointer; */
}

.desktop .text-wrapper-12 {
  color: #9d7a00;

  font-size: 13.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17.8px;
  margin-top: -1.01px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .frame-24 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.73px;
  position: relative;
}

.desktop .frame-25 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.83px;
  position: relative;
}

.desktop .rectangle-7 {
  background-color: #e9edf1;
  height: 17.08px;
  position: relative;
  width: 79.92px;
}

.desktop .rectangle-8 {
  background-color: #e9edf1;
  height: 10.25px;
  position: relative;
  width: 47.13px;
}

.desktop .frame-26 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.31px;
  opacity: 0;
  position: relative;
}

.desktop .frame-27 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.31px;
  position: relative;
}

.desktop .frame-28 {
  align-items: flex-start;
  background-color: var(--bg);
  border-radius: 11.54px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.62px;
  padding: 1.85px 3.69px;
  position: relative;
}

.desktop .text-wrapper-13 {
  color: var(--blinkblack);

  font-size: 5.5px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 6.1px;
  margin-top: -0.46px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.desktop .button {
  all: unset;
  align-items: flex-start;
  background-color: var(--black);
  border-radius: 57.33px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.desktop .button-content {
  all: unset;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  padding: 4.59px 9.17px;
  position: relative;
  width: 100%;
}

.desktop .button-2 {
  all: unset;
  box-sizing: border-box;
  color: var(--blinkwhite);

  font-size: 10.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 11.4px;
  margin-top: -0.57px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .frame-29 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.46px;
  box-shadow: 0px 1.37px 4.1px #0000004c;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.66px;
  padding: 16.39px;
  position: relative;
  width: 156.86px;
}

.desktop .in-process-5 {
  align-items: center;
  background-color: #9eec8f;
  border-radius: 100.84px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.08px;
  justify-content: center;
  padding: 4.03px 8.07px;
  position: relative;
  /* cursor: pointer;11 */
}

.desktop .text-wrapper-14 {
  color: var(--blink-black);

  font-size: 13.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17.8px;
  margin-top: -1.01px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  gap: 2.31px;
  height: 6.83px;
  opacity: 0;
  position: relative;
}

.desktop .frame-31 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.31px;
  margin-bottom: -2.86px;
  position: relative;
}

.desktop .frame-32 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 10.93px;
  padding: 4.1px 0px;
  position: relative;
  width: 112.03px;
}

.desktop .line-2 {
  height: 16.39px;
  position: relative;
  width: 1px;
}

.desktop .frame-33 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.46px;
  box-shadow: 0px 1.37px 4.1px #0000004c;
  display: flex;
  flex-direction: column;
  gap: 32.79px;
  height: 162.45px;
  justify-content: space-around;
  padding: 16.39px;
  position: relative;
  width: 156.86px;
}

.desktop .frame-34 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.93px;
  position: relative;
  width: 112.03px;
}

.desktop .icon-solid-plus-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.46px;
  position: relative;
}

.desktop .icon-solid-plus {
  height: 27.32px !important;
  position: relative !important;
  width: 27.32px !important;
}

.desktop .group-3 {
  background-color: #ffffff;
  border-radius: 17.08px;
  box-shadow: 14px 9px 34px 8px #00000033;
  height: 300px;
  left: 364px;
  position: absolute;
  top: 895px;
  width: 576px;
}

.desktop .frame-35 {
  align-items: center;
  display: inline-flex;
  gap: 5.46px;
  left: 42px;
  position: absolute;
  top: 35px;
}

.desktop .frame-36 {
  align-items: flex-start;
  display: inline-flex;
  gap: 16.39px;
  left: 36px;
  position: absolute;
  top: 84px;
}

.desktop .frame-37 {
  align-items: flex-start;
  display: flex;
  gap: 16.39px;
  position: relative;
  width: 157.54px;
}

.desktop .frame-38 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-right: -0.39px;
  position: relative;
}

.desktop .frame-39 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.46px;
  box-shadow: 0px 1.37px 4.1px #0000004c;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.83px;
  padding: 16.39px;
  position: relative;
}

.desktop .icons-wrapper {
  background-color: #ffffff;
  border: 0.68px solid;
  border-color: var(--blinkgrey-20);
  border-radius: 23.23px;
  height: 46.45px;
  position: relative;
  width: 46.45px;
}

.desktop .icons-2 {
  height: 40px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 40px;
}

.desktop .netflix {
  height: 22px;
  left: 13px;
  position: relative;
  top: 8px;
  width: 12px;
}

.desktop .overlap-group {
  height: 22px;
  position: relative;
}

.desktop .rectangle-9 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.desktop .rectangle-10 {
  height: 22px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 4px;
}

.desktop .vector {
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 12px;
}

.desktop .rectangle-11 {
  height: 10.25px;
  position: relative;
  width: 79.92px;
}

.desktop .frame-40 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.42px;
  opacity: 0;
  position: relative;
}

.desktop .frame-41 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.42px;
  position: relative;
}

.desktop .frame-42 {
  align-items: flex-start;
  background-color: var(--bg);
  border-radius: 17.08px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.83px;
  padding: 2.73px 5.46px;
  position: relative;
}

.desktop .text-wrapper-15 {
  color: var(--blinkblack);

  font-size: 8.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 9px;
  margin-top: -0.68px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.desktop .frame-43 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16.39px;
  position: relative;
}

.desktop .frame-44 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 157.54px;
}

.desktop .frame-45 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.46px;
  box-shadow: 0px 1.37px 4.1px #0000004c;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.83px;
  padding: 16.39px;
  position: relative;
  width: 157.54px;
}

.desktop .meta {
  height: 16px;
  left: 7px;
  position: absolute;
  top: 11px;
  width: 25px;
}

.desktop .in-process-6 {
  align-items: center;
  background-color: var(--blink-green);
  border-radius: 100.84px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.08px;
  justify-content: center;
  padding: 4.03px 8.07px;
  position: relative;
  /* cursor: pointer; */
}

.desktop .glovo {
  height: 26px;
  left: 12px;
  position: absolute;
  top: 7px;
  width: 16px;
}

.desktop .group-4 {
  background-color: #ffffff;
  border-radius: 17.08px;
  box-shadow: 25.96px 19px 35px 5px #00000033;
  height: 300px;
  left: 516px;
  position: absolute;
  top: 1177px;
  width: 576px;
}

.desktop .frame-46 {
  align-items: center;
  display: inline-flex;
  gap: 5.46px;
  left: 42px;
  position: absolute;
  top: 29px;
}

.desktop .frame-47 {
  align-items: flex-start;
  display: inline-flex;
  gap: 16.39px;
  left: 36px;
  position: absolute;
  top: 78px;
}

.desktop .frame-48 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.46px;
  box-shadow: 0px 1.37px 4.1px #0000004c;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.2px;
  padding: 16.39px;
  position: relative;
}

.desktop .coinbase-2 {
  height: 24px;
  left: 7px;
  position: absolute;
  top: 8px;
  width: 25px;
}

.desktop .apple-2 {
  height: 21px;
  left: 12px;
  position: absolute;
  top: 8px;
  width: 16px;
}

.desktop .frame-49 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 151px;
}

.desktop .frame-50 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.46px;
  box-shadow: 0px 1.37px 4.1px #0000004c;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.2px;
  padding: 16.39px;
  position: relative;
  width: 151px;
}

.desktop .amazon-2 {
  height: 24px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 23px;
}

.desktop .p {
  color: #424242;

  font-size: var(--heading-3-font-size);
  font-style: var(--heading-3-font-style);
  font-weight: var(--heading-3-font-weight);
  left: 235px;
  letter-spacing: var(--heading-3-letter-spacing);
  line-height: var(--heading-3-line-height);
  position: absolute;
  text-align: center;
  top: 192px;
  width: 970px;
}

.desktop .text-wrapper-16 {
  color: #535763e3;

  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  left: 387px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  text-align: center;
  top: 321px;
  width: 638px;
}

.desktop .navigation-wrapper {
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px #0000001a;
  display: inline-flex;
  flex-direction: column;
  gap: 48px;
  left: 0;
  padding: 24px 40px;
  position: absolute;
  top: 0;
}

.desktop .navigation {
  height: 47px;
  position: relative;
  width: 1360px;
}

.desktop .frame-51 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 1360px;
}

.desktop .present-desk-v-ex {
  height: 51px;
  position: relative;
  width: 125px;
}

.desktop .frame-52 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 48px;
  position: relative;
}

.desktop .text-wrapper-17,
.desktop .text-wrapper-18 {
  color: var(--theme-inverse-black-white-black-in-light-themes);

  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
  transition: text-shadow 0.3s ease;
  /* 添加过渡效果 */
}

.desktop .text-wrapper-17:hover,
.desktop .text-wrapper-18:hover {
  text-shadow: 2px 2px 1px #9eec8f;
  /* 鼠标悬停时的文字阴影效果 */
}

.desktop .text-wrapper-17 {
  opacity: 0.5;
  /* 只在 text-wrapper-18 中设置透明度 */
}


.desktop .frame-53 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 18px;
  position: relative;
}

.desktop .in-process-7 {
  align-items: center;
  background-color: #9eec8f;
  border-radius: 173.66px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  height: 31px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
  cursor: pointer;
  /* 实现鼠标悬停时显示手形光标 */
  transition: box-shadow 0.3s ease;
  /* 添加平滑过渡效果 */
}

.desktop .in-process-7:hover {
  box-shadow: 0 0 10px #9EEC8F;
  /* 在这里设置光晕效果 */
}

.desktop .text-wrapper-19 {
  color: var(--blink-black);

  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .in-process-8 {
  align-items: center;
  background-color: #535763;
  border-radius: 173.66px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  height: 31px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
  cursor: pointer;
  /* 实现鼠标悬停时显示手形光标 */
  transition: box-shadow 0.3s ease;
  /* 添加平滑过渡效果 */
}

.desktop .in-process-8:hover {
  box-shadow: 0 0 10px #9EEC8F;
  /* 在这里设置光晕效果 */
}

.desktop .text-wrapper-20 {
  color: var(--variable-collection-blink-green);

  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.desktop .vector-2 {
  height: 374px;
  left: 0;
  position: absolute;
  top: 1452px;
  width: 1440px;
}

.desktop .group-5 {
  height: 203px;
  left: 313px;
  position: absolute;
  top: 2046px;
  width: 350px;
}

.desktop .text-wrapper-21 {
  color: var(--blink-black);

  font-size: var(--heading-4-font-size);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  left: 2px;
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  position: absolute;
  top: 0;
  width: 295px;
}

.desktop .text-wrapper-22 {
  color: #535763;

  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  left: 0;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  top: 93px;
  width: 346px;
}

.desktop .group-6 {
  height: 215px;
  left: 309px;
  position: absolute;
  top: 2446px;
  width: 921px;
}

.desktop .AI-auto-resume {
  color: var(--blink-black);

  font-size: var(--heading-4-font-size);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  left: 593px;
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  position: absolute;
  top: 12px;
  width: 295px;
}

.desktop .text-wrapper-23 {
  color: #535763;

  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  left: 591px;
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: absolute;
  top: 105px;
  width: 326px;
}

.desktop .group-wrapper {
  height: 193px;
  left: 0;
  position: absolute;
  top: 0;
  width: 447px;
}

.desktop .group-7 {
  height: 193px;
  position: relative;
  width: 449px;
}

.desktop .frame-54 {
  align-items: flex-start;
  background-color: var(--blinkwhite);
  border: 2.4px solid;
  border-color: var(--blinkgrey-00);
  border-radius: 9.59px;
  box-shadow: 14px 9px 23px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  padding: 18px 20px;
  position: absolute;
  top: 32px;
  width: 447px;
}

.desktop .rectangle-12 {
  background-color: #e9edf1;
  height: 17px;
  position: relative;
  width: 407px;
}

.desktop .frame-55 {
  align-items: flex-start;
  background-color: var(--variable-collection-blink-green);
  border-radius: 9.59px;
  box-shadow: 4px 6px 4px #0000000d;
  display: inline-flex;
  gap: 4px;
  left: 127px;
  padding: 6px 12px;
  position: absolute;
  top: 62px;
}

.desktop .mingcute-bling-line {
  height: 24px;
  position: relative;
  width: 24px;
}

.desktop .text-wrapper-24 {
  color: var(--blink-black);

  font-size: 16.8px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .group-8 {
  height: 53px;
  left: 298px;
  position: absolute;
  top: 76px;
  width: 53px;
}

.desktop .text-wrapper-25 {
  color: var(--blinkblack);

  font-size: 16.8px;
  font-weight: 400;
  height: 25px;
  left: 13px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 0;
  width: 139px;
}

.desktop .text-wrapper-26 {
  color: var(--blink-black);

  font-size: var(--heading-4-font-size);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  left: 317px;
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  position: absolute;
  top: 2857px;
  width: 295px;
}

.desktop .text-wrapper-27 {
  color: #535763;

  font-size: 16px;
  font-weight: 600;
  left: 315px;
  letter-spacing: 0;
  line-height: 22.4px;
  position: absolute;
  top: 2950px;
  width: 346px;
}

.desktop .overlap-wrapper {
  height: 155px;
  left: 841px;
  position: absolute;
  top: 2874px;
  width: 307px;
}

.desktop .overlap-2 {
  height: 155px;
  left: -4px;
  position: relative;
  width: 311px;
}

.desktop .vector-3 {
  height: 44px;
  left: 83px;
  position: absolute;
  top: 16px;
  width: 186px;
}

.desktop .vector-4 {
  height: 27px;
  left: 83px;
  position: absolute;
  top: 71px;
  width: 172px;
}

.desktop .vector-5 {
  height: 29px;
  left: 83px;
  position: absolute;
  top: 109px;
  width: 203px;
}

.desktop .group-9 {
  height: 119px;
  left: 0;
  position: absolute;
  top: 29px;
  width: 98px;
}

.desktop .group-10 {
  background-color: var(--blink-black);
  border-radius: 2.11px;
  height: 40px;
  left: 259px;
  position: absolute;
  top: 0;
  width: 32px;
}

.desktop .overlap-group-wrapper {
  height: 16px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}

.desktop .overlap-group-2 {
  height: 16px;
  position: relative;
}

.desktop .ellipse-5 {
  height: 11px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 14px;
}

.desktop .ellipse-6 {
  background-color: var(--blink-black);
  border: 1.75px solid;
  border-color: #ffffff;
  border-radius: 4.39px;
  height: 9px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 9px;
}

.desktop .rectangle-13 {
  background-color: #ffffff;
  height: 2px;
  left: 4px;
  position: absolute;
  top: 24px;
  width: 23px;
}

.desktop .rectangle-14 {
  background-color: #ffffff;
  height: 2px;
  left: 4px;
  position: absolute;
  top: 28px;
  width: 23px;
}

.desktop .rectangle-15 {
  background-color: #ffffff;
  height: 2px;
  left: 4px;
  position: absolute;
  top: 33px;
  width: 23px;
}

.desktop .group-11 {
  background-color: var(--blink-black);
  border-radius: 2.11px;
  height: 40px;
  left: 230px;
  position: absolute;
  top: 54px;
  width: 32px;
}

.desktop .group-12 {
  background-color: var(--blink-black);
  border-radius: 2.11px;
  height: 40px;
  left: 279px;
  position: absolute;
  top: 115px;
  width: 32px;
}

.desktop .in-process-9 {
  align-items: center;
  background-color: var(--blink-black);
  border-radius: 100.84px;
  display: inline-flex;
  gap: 10.08px;
  justify-content: center;
  left: 188px;
  padding: 4.03px 8.07px;
  position: absolute;
  top: 7px;
  /* cursor: pointer; */
}

.desktop .text-wrapper-28 {
  color: #ffffff;

  font-size: 13.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17.8px;
  margin-top: -1.01px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .in-process-10 {
  align-items: center;
  background-color: var(--blink-black);
  border-radius: 100.84px;
  display: inline-flex;
  gap: 10.08px;
  justify-content: center;
  left: 155px;
  padding: 4.03px 8.07px;
  position: absolute;
  top: 62px;
}

.desktop .in-process-11 {
  align-items: center;
  background-color: var(--blink-black);
  border-radius: 100.84px;
  display: inline-flex;
  gap: 10.08px;
  justify-content: center;
  left: 205px;
  padding: 4.03px 8.07px;
  position: absolute;
  top: 120px;
  /* cursor: pointer; */
}

.desktop .frame-free {
    position: absolute;
    top: 3268px;
    left: 286px;
    display: flex;
    width: 915px;
    flex-direction: column;
    align-items: center;
    gap: 23px;
    text-align: center;

  .text-1 {
    color: #424242;
    text-align: center;

    /* Blink/H3 */
    font-family: Figtree;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 52.8px */
  }

  .text-2 {
    color: #424242;
    font-family: Figtree;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }
}

.desktop .frame-56 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: var(--shadows-menu);
  display: flex;
  flex-direction: column;
  gap: 60px;
  left: 286px;
  padding: 24px;
  position: absolute;
  top: 3511px;
  width: 300px;
  transition: box-shadow 0.3s ease;
  /* 添加平滑过渡效果 */
}

.desktop .frame-56:hover {
  box-shadow: 0px 2px 15px #9eec8f;
  /* 鼠标悬停时的光晕效果 */
}


.desktop .frame-57 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.desktop .frame-58 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.desktop .text-wrapper-29 {
  color: var(--black);

  font-size: var(--heading-5-font-size);
  font-style: var(--heading-5-font-style);
  font-weight: var(--heading-5-font-weight);
  letter-spacing: var(--heading-5-letter-spacing);
  line-height: var(--heading-5-line-height);
  margin-top: -1px;
  position: relative;
  width: 164px;
}

.desktop .text-wrapper-30 {
  font-size: var(--heading-4-font-size);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  position: relative;
  width: 164px;
}

.desktop .text-wrapper-line-through {
  color: #999;
  text-decoration: line-through;
  text-decoration-color: var(--black)
}

.desktop .text-wrapper-free {
  color: var(--black);
  margin-left: 8px;
}

.desktop .frame-59 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  margin-right: -8px;
  position: relative;
}

.desktop .frame-60 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3px;
  position: relative;
}

.desktop .frame-61 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3px;
  position: relative;
}

.desktop .ellipse-wrapper {
  height: 22px;
  position: relative;
  width: 22px;
}

.desktop .ellipse-7 {
  background-color: #9eec8f;
  border-radius: 3px;
  height: 6px;
  left: 8px;
  position: relative;
  top: 8px;
  width: 6px;
}

.desktop .div-2 {
  color: #535763;

  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .span {

  font-size: var(--body-2-bold-font-size);
  font-style: var(--body-2-bold-font-style);
  font-weight: var(--body-2-bold-font-weight);
  letter-spacing: var(--body-2-bold-letter-spacing);
  line-height: var(--body-2-bold-line-height);
}

.desktop .text-wrapper-31 {

  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
}

.desktop .frame-62 {
  align-items: flex-start;
  background-color: #9eec8ff2;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  padding: 6px 12px;
  position: relative;
}

.desktop .fluent-star-emphasis {
  height: 21px;
  position: relative;
  width: 21px;
}

.desktop .text-wrapper-32 {
  color: var(--blink-black);

  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.desktop .frame-63 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  /* 默认阴影 */
  display: flex;
  flex-direction: column;
  gap: 60px;
  left: 606px;
  padding: 24px;
  position: absolute;
  top: 3511px;
  width: 300px;
  transition: box-shadow 0.3s ease;
  /* 添加平滑过渡效果 */
}

.desktop .frame-63:hover {
  box-shadow: 0px 2px 15px #9eec8f;
  /* 鼠标悬停时的光晕效果 */
}


.desktop .text-wrapper-33 {
  color: #23272d;

  font-size: var(--heading-5-font-size);
  font-style: var(--heading-5-font-style);
  font-weight: var(--heading-5-font-weight);
  letter-spacing: var(--heading-5-letter-spacing);
  line-height: var(--heading-5-line-height);
  margin-top: -1px;
  position: relative;
  width: 164px;
}

.desktop .text-wrapper-34 {
  font-size: var(--heading-4-font-size);
  font-style: var(--heading-4-font-style);
  font-weight: var(--heading-4-font-weight);
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  position: relative;
  width: 164px;
}

.desktop .frame-64 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  margin-right: -5px;
  position: relative;
}

.desktop .frame-65 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: var(--shadows-menu);
  display: flex;
  flex-direction: column;
  gap: 60px;
  left: 926px;
  padding: 24px;
  position: absolute;
  top: 3511px;
  width: 300px;
  transition: box-shadow 0.3s ease;
  /* 添加平滑过渡效果 */
}

.desktop .frame-65:hover {
  box-shadow: 0px 2px 15px #9eec8f;
  /* 鼠标悬停时的光晕效果 */
}

.desktop .frame-66 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  position: relative;
}

.desktop .text-wrapper-35 {
  color: #535763;

  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .element-job-matches-day {
  color: #000000;

  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .text-wrapper-36 {
  color: #535763;

  font-size: var(--body-2-bold-font-size);
  font-style: var(--body-2-bold-font-style);
  font-weight: var(--body-2-bold-font-weight);
  letter-spacing: var(--body-2-bold-letter-spacing);
  line-height: var(--body-2-bold-line-height);
}

.desktop .text-wrapper-37 {
  color: #535763;

  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
}

.desktop .group-13 {
  height: 69px;
  left: 1142px;
  position: absolute;
  top: 1134px;
  width: 80px;
}

.desktop .rectangle-16 {
  background-color: #d9d9d9;
  height: 25px;
  left: -3386px;
  position: absolute;
  top: 3962px;
  width: 25px;
}